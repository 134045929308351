import React from 'react';
import Table from 'cccisd-graphql-table';
import IconSmile from 'cccisd-icons/smile';
import IconSad from 'cccisd-icons/wondering';
import _round from 'lodash/round';

const SummaryTable = props => {
    const _renderDataFace = value => {
        if (value < props.targetScore) {
            return (
                <>
                    <span style={{ color: 'red' }}>
                        <IconSad spaceRight />
                    </span>
                    {value}%
                </>
            );
        }
        return (
            <>
                <span style={{ color: '#3673b6' }}>
                    <IconSmile spaceRight />
                </span>
                {value}%
            </>
        );
    };

    const columns = [
        { name: 'group.label', label: 'Group Name', sort: true },
        {
            name: 'childRoles.metricspawn.assignmentProgressSummary.lastSessionHeld',
            label: 'Current Session',
            sort: true,
        },
        // { name: 'lastSession', label: 'Date of Last Session', sort: true },
        {
            name: 'childRoles.metricspawn.assignmentProgressSummary.youthFidelityScoreCombinedAvg',
            label: 'Average Youth Adherence',
            sort: true,
            render: ({ value }) => _renderDataFace(_round(value)),
        },
        {
            name:
                'childRoles.metricspawn.assignmentProgressSummary.caregiverFidelityScoreCombinedAvg',
            label: 'Average Caregiver Adherence',
            sort: true,
            render: ({ value }) => _renderDataFace(_round(value)),
        },
        {
            name: 'childRoles.metricspawn.assignmentProgressSummary.familyFidelityScoreCombinedAvg',
            label: 'Average Family Adherence',
            sort: true,
            render: ({ value }) => _renderDataFace(_round(value)),
        },
    ];

    return (
        <Table
            rowKey="group.groupId"
            query={props.query}
            graphqlVariables={{
                groupIds: props.filters.groupIds,
                startDate: props.filters.startDate,
                endDate: props.filters.endDate,
            }}
            columns={columns}
            // render={tableProps => console.log(tableProps)}
        />
    );
};

export default SummaryTable;
