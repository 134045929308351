import React from 'react';
import { Field, CccisdToggle, CccisdInput, CccisdFieldWrapper } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    title: 'Fidelity Adherence',
    description: 'Average per session breakdown',
    chart: {
        targetLineValue: '80',
        verticalScaleMin: '0',
        verticalScaleMax: '100',
        scoreSuffix: '%',
        showLegend: true,
        showLabels: true,
        colorScheme: 'default',
    },
};

const colorSchemeOptions = [
    { value: 'default', label: 'Default' },
    { value: 'highlightAboveBenchmark', label: 'Highlight Above Benchmark' },
];

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Fidelity Adherence Chart?" />
            <Field name="title" component={CccisdInput} label="Title" />
            <Field name="description" component={CccisdInput} label="Description" />
            <h4>Chart Settings</h4>
            <Field name="chart.showLegend" component={CccisdToggle} label="Show Legend?" />
            <Field name="chart.showLabels" component={CccisdToggle} label="Show Labels?" />
            <Field name="chart.colorScheme">
                {({
                    field, // { name, value, onChange, onBlur }
                    form, // also values, setXXXX, handleXXXX, dirty, isValid, status, etc.
                }) => {
                    return (
                        <CccisdFieldWrapper field={field} form={form} label="Color Scheme">
                            <div>
                                {colorSchemeOptions.map(option => (
                                    <button
                                        key={option.value}
                                        type="button"
                                        className={
                                            'btn btn-default ' +
                                            (option.value === field.value ? 'active' : '')
                                        }
                                        onClick={() => {
                                            form.setFieldValue('chart.colorScheme', option.value);
                                        }}
                                    >
                                        {option.label}
                                    </button>
                                ))}
                            </div>
                        </CccisdFieldWrapper>
                    );
                }}
            </Field>
            <Field
                name="chart.targetLineValue"
                component={CccisdInput}
                label="Benchmark Value"
                type="number"
            />
            <Field
                name="chart.verticalScaleMin"
                component={CccisdInput}
                label="Y-axis Min Value"
                type="number"
            />
            <Field
                name="chart.verticalScaleMax"
                component={CccisdInput}
                label="Y-axis Max Value"
                type="number"
            />
            <Field name="chart.scoreSuffix" component={CccisdInput} label="Y-axis Suffix" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
