/* eslint-disable no-restricted-globals */
import React from 'react';
import _isNil from 'lodash/isNil';
import _union from 'lodash/union';

/*
 * Displays the facilitators used for this group/session in all sessions
 *
 * A row per facilitator
 */
export default settings => {
    return ({ value, row }) => {
        let listPath = settings.listPath;
        const metric = settings.metric;
        let nameList = row[listPath];

        if (_isNil(nameList)) {
            return <div />;
        }

        let facilitators = [];

        nameList.forEach(response => {
            facilitators = _union(
                facilitators,
                response.devTagLists[metric].forEach(name => {
                    if (!name.includes('_')) {
                        facilitators.push(name);
                    }
                })
            );
        });

        return (
            <div>
                {facilitators &&
                    facilitators.map(name => {
                        if (!_isNil(name)) {
                            return (
                                <div>
                                    <ul style={{ listStyle: 'none', paddingLeft: '0' }}>
                                        <li key={name}>{name}</li>
                                    </ul>
                                </div>
                            );
                        }

                        return '';
                    })}
            </div>
        );
    };
};
