import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import overallFidelityQuery from './overallFidelity.graphql';
import FidelityChart from './components/FidelityChart';
import _round from 'lodash/round';
import style from './style.css';

const Component = props => {
    const [hidden, setHidden] = useState(true);

    const { title, description, show, chart } = props.settings;
    const { isPreview, query } = props;

    const [data, loading] = useQuery({
        query: isPreview ? overallFidelityQuery : query,
        variables: props.filters,
    });

    // Nivo charts sometimes dont render. Not sure what is going on. This function prevents chart render before parent element can render which seems to fix the problem.
    // Additional notes: Charts need parent element to have an explicit height to render. The chart is creating a parent div which uses a percent height. If this div is modified in the DOM after render, the chart will draw.
    // More notes: This is only happening in Report Center GUI. Does not happen in Reports GUI.
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const chartData = props.convertDataStructure(data);

    return (
        <>
            {show && (
                <div className={style.overallTable}>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{title}</span>
                    </div>
                    <div>{description}</div>
                    {hidden ? <div /> : <FidelityChart data={chartData} settings={chart} />}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        // console.log(data);
        let convertedData = [];

        if (data && data.groups.treatmentGroupList.length > 0) {
            convertedData = [
                'Overall Fidelity',
                'Foundational Fidelity Subscale',
                'Group Leadership Fidelity Subscale',
            ].map(fidType => {
                let dataValues = [];
                const APL =
                    data.groups.treatmentGroupList[0].childRoles.metricspawn.assignmentProgressList;

                let fidelityTag;
                if (fidType === 'Overall Fidelity') {
                    fidelityTag = 'FidelityChecklistScore';
                }
                if (fidType === 'Foundational Fidelity Subscale') {
                    fidelityTag = 'FidelityFoundationlElementsScore';
                }
                if (fidType === 'Group Leadership Fidelity Subscale') {
                    fidelityTag = 'FidelityLeadershipScore';
                }

                for (let i = 0; i < 7; i++) {
                    const session = APL.find(s => s.deployment.label === `Session ${i + 1}`);
                    let y = null;

                    if (session) {
                        const score = session.devTags[fidelityTag];
                        if (typeof score !== 'object') {
                            y = _round(Number(score), 1);
                        }
                    }
                    dataValues.push({
                        x: i + 1,
                        y,
                    });
                }
                return {
                    id: fidType,
                    data: dataValues,
                };
            });
        } else {
            convertedData = [
                {
                    id: 'Overall Fidelity',
                    data: [
                        {
                            x: 1,
                            y: 1.5,
                        },
                        {
                            x: 2,
                            y: 1.5,
                        },
                        {
                            x: 3,
                            y: 2.5,
                        },
                        {
                            x: 4,
                            y: 2.5,
                        },
                        {
                            x: 5,
                            y: 2.5,
                        },
                        {
                            x: 4,
                            y: 2.5,
                        },
                        {
                            x: 5,
                            y: 2.5,
                        },
                    ],
                },
                {
                    id: 'Foundational Fidelity',
                    data: [
                        {
                            x: 1,
                            y: 0.63,
                        },
                        {
                            x: 2,
                            y: 5,
                        },
                        {
                            x: 3,
                            y: 4.64,
                        },
                        {
                            x: 4,
                            y: 3,
                        },
                        {
                            x: 5,
                            y: 4.14,
                        },
                        {
                            x: 4,
                            y: 3,
                        },
                        {
                            x: 5,
                            y: 4.14,
                        },
                    ],
                },
                {
                    id: 'Leadership Fidelity',
                    data: [
                        {
                            x: 1,
                            y: 5,
                        },
                        {
                            x: 2,
                            y: 4,
                        },
                        {
                            x: 3,
                            y: 4.66,
                        },
                        {
                            x: 4,
                            y: 4.18,
                        },
                        {
                            x: 5,
                            y: 5,
                        },
                        {
                            x: 4,
                            y: 4.18,
                        },
                        {
                            x: 5,
                            y: 5,
                        },
                    ],
                },
            ];
        }
        // console.log({ convertedData });
        return convertedData;
    },
};

export default Component;
