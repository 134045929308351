import React from 'react';
import style from './style.css';
import _get from 'lodash/get';

export default settings => {
    return ({ value, row }) => {
        let minAge = 100;
        let maxAge = 0;

        minAge = _get(row, settings.min);
        maxAge = _get(row, settings.max);

        return (
            <div className={style.cell}>
                {minAge} {' to '} {maxAge}
            </div>
        );
    };
};
