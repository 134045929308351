import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-graphql-table';
import { BrowserOnly } from 'cccisd-laravel-report';
import IconMenu from 'cccisd-icons/menu7';
import IconSpinner from 'cccisd-icons/spinner8';
import _round from 'lodash/round';
import style from './style.css';

const SummaryTable = props => {
    const { colors } = props;

    const _renderSessionCols = (fill, fillVal) => {
        // fill = array of cell indexes to fill
        // fillVal = value to fill in these cells
        const sessionCols = [];

        for (let i = 1; i <= props.totalSessions; i++) {
            let cellVal;
            if (fill) {
                if (fill.includes(i)) {
                    cellVal = fillVal;
                }
            } else {
                cellVal = i;
            }

            sessionCols.push(
                <td className={style.tableHeadingAlt} key={i}>
                    {cellVal}
                </td>
            );
        }
        return sessionCols;
    };

    const _renderTableBody = data => {
        // console.log({ data });
        if (data.length < 1) {
            return (
                <tr>
                    <td colSpan={props.totalSessions * 2 + 4}>
                        No data has been entered for the selected groups.
                    </td>
                </tr>
            );
        }
        return data.map(facilitator => {
            return (
                <React.Fragment key={facilitator.groupName}>
                    <tr key={facilitator.id}>
                        <td>{facilitator.name}</td>
                        {facilitator.scores.map((score, index) => {
                            let scoreValue = score;
                            if (typeof score !== 'object') {
                                scoreValue = _round(Number(score), 1);
                            }
                            return <td key={index}>{scoreValue}</td>;
                        })}
                    </tr>
                </React.Fragment>
            );
        });
    };

    const renderTable = tableProps => {
        if (tableProps.data.length > 0 && !tableProps.loading) {
            // console.log(tableProps.data);
            return (
                <div>
                    <BrowserOnly>
                        <div className="btn-group" style={{ marginBottom: '0.7em' }}>
                            <button
                                type="button"
                                className={`btn btn-sm btn-default dropdown-toggle ${
                                    style.multipurposeButton
                                } ${tableProps.loading ? style.rotating : ''}`}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {tableProps.loading ? <IconSpinner /> : <IconMenu />}
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a href="" onClick={tableProps.loadData}>
                                        Reload data
                                    </a>
                                </li>
                                <li>
                                    <a href="" onClick={tableProps.downloadCsv}>
                                        Download CSV
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </BrowserOnly>
                    <table className={style.table}>
                        <thead>
                            <tr>
                                <th
                                    className={style.tableHeading}
                                    rowSpan="2"
                                    style={colors ? { backgroundColor: colors.primary } : {}}
                                >
                                    Facilitator Name
                                </th>
                                <th
                                    colSpan={props.totalSessions}
                                    className={style.tableHeading}
                                    style={colors ? { backgroundColor: colors.primary } : {}}
                                >
                                    <span>Session Number</span>
                                </th>
                            </tr>
                            <tr>{_renderSessionCols()}</tr>
                        </thead>
                        <tbody>
                            {_renderTableBody(props.convertDataStructure(tableProps.data))}
                        </tbody>
                    </table>
                </div>
            );
        }
        return null;
    };

    const columns = [{ name: 'group.label', label: 'Group Name', sort: true }];

    return (
        <Table
            rowKey="group.groupId"
            query={props.query}
            graphqlVariables={{
                groupIds: props.filters.groupIds,
                startDate: props.filters.startDate,
                endDate: props.filters.endDate,
            }}
            columns={columns}
            render={tableProps => renderTable(tableProps)}
        />
    );
};

SummaryTable.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    query: PropTypes.object,
    colors: PropTypes.object,
    totalSessions: PropTypes.number,
    convertDataStructure: PropTypes.func,
    targetScore: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

SummaryTable.defaultProps = {
    filters: {},
    totalSessions: 7,
    convertDataStructure: data => {
        let convertedData = [];
        if (data.length > 0) {
            const apl = data[0]['childRoles.metricspawn.assignmentProgressList'];
            convertedData = [
                {
                    id: 0,
                    name: 'Facilitator 1',
                    scores: apl.map(i => i.devTags.Facilitator1EvaluationScore),
                },
                {
                    id: 1,
                    name: 'Facilitator 2',
                    scores: apl.map(i => i.devTags.Facilitator2EvaluationScore),
                },
                {
                    id: 2,
                    name: 'Facilitator 3',
                    scores: apl.map(i => i.devTags.Facilitator3EvaluationScore),
                },
            ];
        } else {
            convertedData = [
                {
                    id: 0,
                    name: 'Facilitator 1',
                    scores: ['1.123456789', '3.43567', null, null, null, null, null],
                },
                {
                    id: 1,
                    name: 'Facilitator 2',
                    scores: ['5', '4', '4', '5', '2', null, null],
                },
                {
                    id: 2,
                    name: 'Facilitator 3',
                    scores: ['3', '3', '3', '2', '2', null, null],
                },
            ];
        }
        // console.log({ convertedData });
        return convertedData;
    },
};

export default SummaryTable;
