import React, { useState } from 'react';
import Color from 'color';
import { Html } from 'cccisd-wysiwyg';
import { BrowserOnly } from 'cccisd-laravel-report';
import IconInfo from 'cccisd-icons/info2';
import IconPlus from 'cccisd-icons/plus2';
import IconMinus from 'cccisd-icons/minus2';
import style from './style.css';

const Info = props => {
    const [boxOpen, setBoxOpen] = useState(true);
    const { colors } = props;

    return (
        <div className={style.wrapper} style={colors ? { borderColor: colors.primary } : {}}>
            <div className={style.header} style={colors ? { backgroundColor: colors.primary } : {}}>
                <div>
                    {props.icon || <IconInfo spaceRight />}
                    {props.title || 'Measurement Information'}
                </div>
                <BrowserOnly>
                    <button
                        type="button"
                        className={`btn btn-sm ${style.menuButton}`}
                        onClick={() => setBoxOpen(!boxOpen)}
                        style={colors ? { backgroundColor: Color(colors.primary).darken(0.2) } : {}}
                    >
                        {boxOpen ? <IconMinus /> : <IconPlus />}
                    </button>
                </BrowserOnly>
            </div>
            {boxOpen ? (
                <div className={style.content}>
                    <Html content={props.content} />
                </div>
            ) : null}
        </div>
    );
};

export default Info;
