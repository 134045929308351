import React from 'react';
import PropTypes from 'prop-types';
import style from './style.css';

const ProgressRing = props => {
    const radius = props.radius;
    const stroke = props.stroke;
    const normalizeRadius = radius - stroke * 2;
    const circumference = normalizeRadius * 2 * Math.PI;

    const value = props.value;
    const total = props.total;

    const _getColor = score => {
        if (score < 80) {
            return '#d03916';
        }
        return '#3673b6';
    };

    const strokeDashoffset = circumference - (value / total) * circumference;

    return (
        <div className={style.wrapper}>
            {!props.dontShowPercent && (
                <div
                    className={style.percent}
                    style={{ fontSize: `${radius / 2 - 2}px`, color: _getColor(value) }}
                >
                    {Math.round((value / total) * 100)}%
                </div>
            )}
            <svg className={style.progressRing} width={radius * 2} height={radius * 2}>
                <circle
                    className={style.circleBg}
                    stroke="#eee"
                    strokeWidth="4"
                    fill="transparent"
                    r={normalizeRadius}
                    cx={radius}
                    cy={radius}
                />
                <circle
                    className={style.circle}
                    strokeDasharray={circumference + ' ' + circumference}
                    style={{
                        strokeDashoffset,
                    }}
                    stroke={_getColor(value)}
                    strokeWidth="4"
                    fill="transparent"
                    r={normalizeRadius}
                    cx={radius}
                    cy={radius}
                />
            </svg>
        </div>
    );
};

ProgressRing.propTypes = {
    value: PropTypes.number.isRequired,
    total: PropTypes.number,
    radius: PropTypes.number,
    stroke: PropTypes.number,
    dontShowPercent: PropTypes.bool,
};

ProgressRing.defaultProps = {
    total: 100,
    radius: 20,
    stroke: 4,
    dontShowPercent: false,
};

export default ProgressRing;
