import React from 'react';
import moment from 'moment';
export default (settings, callbackHandler) => {
    return ({ value, row }) => {
        return (
            <div key={row} className="text-center">
                {!value || value === null ? null : moment(value).format('MM-DD-YYYY')}
            </div>
        );
    };
};
