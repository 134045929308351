import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'summaryTableInfoTab',
    label: 'Info Tab',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
