import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import chartsQuery from './chartsQuery.graphql';
import OutcomesGroupChart from './Chart';
import _round from 'lodash/round';
import style from './style.css';

const OutcomesParticipantCharts = props => {
    const [hidden, setHidden] = useState(true);

    const { title, description, show, chart } = props.settings;
    const { isPreview, query } = props;

    const [data, loading] = useQuery({
        query: isPreview ? chartsQuery : query,
        variables: props.filters,
    });

    // Nivo charts sometimes dont render. Not sure what is going on. This function prevents chart render before parent element can render which seems to fix the problem.
    // Additional notes: Charts need parent element to have an explicit height to render. The chart is creating a parent div which uses a percent height. If this div is modified in the DOM after render, the chart will draw.
    // More notes: This is only happening in Report Center GUI. Does not happen in Reports GUI.
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const chartData = props.convertDataStructure(data);

    const _renderCharts = () => {
        if (chartData && chartData.length > 0) {
            return chartData.map(participant => {
                return (
                    <div key={participant.name}>
                        <h5>{participant.name}</h5>
                        <OutcomesGroupChart data={participant.data} settings={chart} />
                    </div>
                );
            });
        }
        return <div>No data found for the selected filters.</div>;
    };

    return (
        <>
            {show && (
                <div className={style.overallTable}>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{title}</span>
                    </div>
                    <div>{description}</div>
                    {hidden ? <div /> : _renderCharts()}
                </div>
            )}
        </>
    );
};

OutcomesParticipantCharts.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

OutcomesParticipantCharts.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        // console.log(data);
        let convertedData = [];

        if (data && data.groups.treatmentGroupList.length > 0) {
            convertedData = data.groups.treatmentGroupList.map(group => {
                if (group.childRoles.participantSummary) {
                    const summary = group.childRoles.participantSummary;
                    return {
                        name: group.group.label,
                        data: [
                            {
                                id: 'Youth Summary',
                                color: '#3673b6',
                                data: [
                                    {
                                        x: '',
                                        y: null,
                                    },
                                    {
                                        x: 'Pre',
                                        y: summary ? _round(Number(summary.youthPre), 1) : null,
                                    },
                                    {
                                        x: 'Post',
                                        y: summary ? _round(Number(summary.youthPost), 1) : null,
                                    },
                                    {
                                        x: ' ',
                                        y: null,
                                    },
                                ],
                            },
                            {
                                id: 'Caregiver Summary',
                                color: '#65a84b',
                                data: [
                                    {
                                        x: '',
                                        y: null,
                                    },
                                    {
                                        x: 'Pre',
                                        y: summary ? _round(Number(summary.caregiverPre), 1) : null,
                                    },
                                    {
                                        x: 'Post',
                                        y: summary
                                            ? _round(Number(summary.caregiverPost), 1)
                                            : null,
                                    },
                                    {
                                        x: ' ',
                                        y: null,
                                    },
                                ],
                            },
                        ],
                    };
                }
                return null;
            });
        } else {
            convertedData = [
                {
                    name: 'MB0101',
                    data: [
                        {
                            id: 'Youth Summary',
                            color: '#3673b6',
                            data: [
                                {
                                    x: '',
                                    y: null,
                                },
                                {
                                    x: 'Pre',
                                    y: 60,
                                },
                                {
                                    x: 'Post',
                                    y: 70,
                                },
                                {
                                    x: ' ',
                                    y: null,
                                },
                            ],
                        },
                        {
                            id: 'Caregiver Summary',
                            color: '#65a84b',
                            data: [
                                {
                                    x: '',
                                    y: null,
                                },
                                {
                                    x: 'Pre',
                                    y: 65,
                                },
                                {
                                    x: 'Post',
                                    y: 75,
                                },
                                {
                                    x: ' ',
                                    y: null,
                                },
                            ],
                        },
                    ],
                },
                {
                    name: 'MB0102',
                    data: [
                        {
                            id: 'Youth Summary',
                            color: '#3673b6',
                            data: [
                                {
                                    x: '',
                                    y: null,
                                },
                                {
                                    x: 'Pre',
                                    y: 65,
                                },
                                {
                                    x: 'Post',
                                    y: 75,
                                },
                                {
                                    x: ' ',
                                    y: null,
                                },
                            ],
                        },
                        {
                            id: 'Caregiver Summary',
                            color: '#65a84b',
                            data: [
                                {
                                    x: '',
                                    y: null,
                                },
                                {
                                    x: 'Pre',
                                    y: 70,
                                },
                                {
                                    x: 'Post',
                                    y: 72,
                                },
                                {
                                    x: ' ',
                                    y: null,
                                },
                            ],
                        },
                    ],
                },
            ];
        }
        // console.log({ convertedData });
        const filteredData = convertedData.filter(d => d !== null);
        return filteredData;
    },
};

export default OutcomesParticipantCharts;
