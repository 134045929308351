import React from 'react';
import Color from 'color';
import moment from 'moment';
import IconChart from 'cccisd-icons/chart';
import _uniq from 'lodash/uniq';
import style from './style.css';

const ReportHeader = props => {
    const fakeData = props.isPreview
        ? {
              header: {
                  startDate: '2020-01-13',
                  endDate: '2020-02-29',
                  reportCreatedDate: 'March 01, 2020',
                  groupLeaderName: 'Some Person',
                  numGroups: 5,
                  numParticipants: 28,
              },
              groupIds: [0, 1, 2, 3, 4],
          }
        : null;

    const {
        header: {
            title,
            subtitle,
            description,
            groupLeaderText,
            reportingPeriodText,
            createdDateText,
            numGroupsText,
            numParticipantsText,
        },
        colors,
    } = props.settings;

    const selectedIds = props.filters.groupIds ? props.filters.groupIds : fakeData.groupIds;

    const getHeaderValues = () => {
        const { data } = props;
        const numGroups = selectedIds.length;

        const filteredGroups = data.groups.treatmentGroupList.filter(tg =>
            selectedIds.includes(tg.group.groupId)
        );

        let numParticipants = 0;
        for (let i = 0; i < filteredGroups.length; i++) {
            numParticipants += filteredGroups[i].childRoles.participantCount;
        }

        let groupLeaders = [];
        if (props.individuals) {
            const treatmentGroup = props.data.groups.treatmentGroupList.filter(tgl => {
                return tgl.childRoles.participantList.find(pl =>
                    selectedIds.includes(pl.pawn.pawnId)
                );
            });

            const individualName = treatmentGroup[0].childRoles.participantList.find(pl =>
                selectedIds.includes(pl.pawn.pawnId)
            );
            groupLeaders = [individualName.fields.participantId];
        }

        if (filteredGroups.length === 1 && !props.individuals) {
            if (
                filteredGroups[0].childRoles.metricspawn.assignmentProgressSummary
                    .caregiverFacilitatorNames &&
                filteredGroups[0].childRoles.metricspawn.assignmentProgressSummary
                    .childFacilitatorNames
            ) {
                filteredGroups[0].childRoles.metricspawn.assignmentProgressSummary.caregiverFacilitatorNames.forEach(
                    cf => groupLeaders.push(cf.value)
                );
                filteredGroups[0].childRoles.metricspawn.assignmentProgressSummary.childFacilitatorNames.forEach(
                    cf => groupLeaders.push(cf.value)
                );
                groupLeaders = _uniq(groupLeaders.filter(d => d !== null)).map(str =>
                    str.replace(/[, ]+/g, ' ').trim()
                );
            }
        } else if (filteredGroups.length !== 1 && !props.individuals) {
            groupLeaders = ['Multiple Groups'];
        }

        return { numGroups, numParticipants, groupLeaders };
    };

    const _renderGroupFilters = () => {
        if (!props.individuals) {
            const groups = selectedIds;

            const getGroupName = id => {
                const findGroup = props.data.groups.treatmentGroupList.find(
                    tg => tg.group.groupId === Number(id)
                );
                return findGroup ? findGroup.group.label : '';
            };
            return (
                <>
                    {groups.map(group => {
                        const groupName = getGroupName(group);
                        if (groupName !== '') {
                            return (
                                <div
                                    key={group}
                                    className={style.groupFilter}
                                    style={
                                        colors
                                            ? {
                                                  border: `1px solid ${colors.primary}`,
                                                  color: colors.primary,
                                              }
                                            : {}
                                    }
                                >
                                    {groupName}
                                </div>
                            );
                        }
                        return null;
                    })}
                </>
            );
        }
        return null;
    };

    const header = getHeaderValues();
    return (
        <>
            <div className={style.header}>
                <div className={style.headerTop}>
                    <div
                        className={style.topLeft}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div className={style.headerTitle}>{title}</div>
                    </div>
                    <div
                        className={style.topCenter}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div
                            className={style.topCenterHighlight}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).lighten(0.1),
                                      }
                                    : {}
                            }
                        />
                    </div>
                    <div className={style.topRight} style={colors ? { color: colors.primary } : {}}>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{reportingPeriodText} </span>
                            {props.filters.startDate && props.filters.endDate ? (
                                <>
                                    {props.filters.startDate} to {props.filters.endDate}
                                </>
                            ) : (
                                'None specified'
                            )}
                        </div>
                        <div>
                            <span style={{ fontWeight: 'bold' }}>{createdDateText} </span>
                            {moment(new Date()).format('MMMM Do YYYY')}
                        </div>
                    </div>
                </div>
                <div className={style.headerBot}>
                    <div className={style.botLeft}>
                        <div
                            className={style.description}
                            style={colors ? { color: colors.primary } : {}}
                        >
                            {description}
                        </div>
                    </div>
                    <div
                        className={style.botCenter}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div
                            className={style.botCenterShadow}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).darken(0.1),
                                      }
                                    : {}
                            }
                        />
                        <div
                            className={style.botCenterHighlight}
                            style={
                                colors
                                    ? {
                                          backgroundColor: Color(colors.primary).darken(0.05),
                                      }
                                    : {}
                            }
                        />
                    </div>
                    <div
                        className={style.botRight}
                        style={colors ? { backgroundColor: colors.primary } : {}}
                    >
                        <div style={{ fontWeight: 'bold', fontSize: '18px' }}>{subtitle}</div>
                    </div>
                </div>
            </div>
            <div
                className={style.subheader}
                style={
                    colors
                        ? {
                              color: colors.primary,
                          }
                        : {}
                }
            >
                <div className={style.subheaderText}>
                    <div>
                        <span
                            style={
                                props.individuals
                                    ? { fontWeight: 'bold', fontSize: '1.4em' }
                                    : { fontWeight: 'bold' }
                            }
                        >
                            {groupLeaderText}{' '}
                        </span>
                        <span style={props.individuals ? { fontSize: '1.4em' } : {}}>
                            {header.groupLeaders.join(', ')}
                        </span>
                    </div>
                    {!props.individuals && (
                        <>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>{numGroupsText} </span>
                                {header.numGroups}
                            </div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>{numParticipantsText} </span>
                                {header.numParticipants}
                            </div>
                        </>
                    )}
                </div>
                <div
                    className={style.subheaderIcon}
                    style={props.individuals ? { fontSize: '2em' } : {}}
                >
                    <IconChart />
                </div>
            </div>
            {!props.individuals && (
                <div className={style.filters}>
                    <span
                        className={style.filtersText}
                        style={
                            colors
                                ? {
                                      color: colors.primary,
                                  }
                                : {}
                        }
                    >
                        Current Report Includes:
                    </span>
                    {_renderGroupFilters()}
                </div>
            )}
        </>
    );
};

export default ReportHeader;
