import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import adherenceQuery from './adherenceQuery.graphql';
import Callout from './components/Callout';
import _round from 'lodash/round';
import _mean from 'lodash/mean';
import style from './style.css';

const Component = props => {
    const { colors } = props.settings;
    const { isPreview, query } = props;

    const [data, loading] = useQuery({
        query: isPreview ? adherenceQuery : query,
        variables: props.filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const calloutsData = props.convertDataStructure(data);

    const { teen, caregiver, family } = calloutsData;

    return (
        <>
            {props.settings.show && (
                <div className={style.callouts}>
                    {props.settings.teen.show && (
                        <Callout
                            handle="teen"
                            settings={{ ...props.settings.teen, colors }}
                            data={teen}
                        />
                    )}
                    {props.settings.caregiver.show && (
                        <Callout
                            handle="caregiver"
                            settings={{ ...props.settings.caregiver, colors }}
                            data={caregiver}
                        />
                    )}
                    {props.settings.family.show && (
                        <Callout
                            handle="family"
                            settings={{ ...props.settings.family, colors }}
                            data={family}
                        />
                    )}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        // console.log(data);
        let convertedData = {};

        if (data && data.groups.treatmentGroupList.length > 0) {
            let teenScores = [];
            let caregiverScores = [];
            let familyScores = [];
            data.groups.treatmentGroupList.forEach(tg => {
                const aps = tg.childRoles.metricspawn.assignmentProgressSummary;

                if (aps.youthFidelityScoreCombinedAvg !== null) {
                    teenScores.push(aps.youthFidelityScoreCombinedAvg);
                }
                if (aps.caregiverFidelityScoreAvg !== null) {
                    caregiverScores.push(aps.caregiverFidelityScoreAvg);
                }
                if (aps.familyFidelityScoreAvg !== null) {
                    familyScores.push(aps.familyFidelityScoreAvg);
                }
            });

            // console.log(teenScores, caregiverScores, familyScores);

            const teenAdherenceAvg = teenScores.length > 0 ? _round(_mean(teenScores)) : NaN;
            const caregiverAdherenceAvg =
                caregiverScores.length > 0 ? _round(_mean(caregiverScores)) : NaN;
            const familyAdherenceAvg = familyScores.length > 0 ? _round(_mean(familyScores)) : NaN;

            convertedData = {
                teen: {
                    adherence: teenAdherenceAvg,
                    target: 80,
                },
                caregiver: {
                    adherence: caregiverAdherenceAvg,
                    target: 80,
                },
                family: {
                    adherence: familyAdherenceAvg,
                    target: 80,
                },
            };
        } else {
            convertedData = {
                teen: {
                    adherence: 84,
                    target: 80,
                },
                caregiver: {
                    adherence: 70,
                    target: 80,
                },
                family: {
                    adherence: 56,
                    target: 80,
                },
            };
        }
        // console.log({ convertedData });
        return convertedData;
    },
};

export default Component;
