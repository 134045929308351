import React, { useState } from 'react';
import Color from 'color';
import Tooltip from 'cccisd-tooltip';
import ProgressRing from '../../../../../../../ProgressRing';
import { BrowserOnly } from 'cccisd-laravel-report';
import IconSmile from 'cccisd-icons/smile';
import IconSad from 'cccisd-icons/wondering';
import IconPieChart from 'cccisd-icons/pie-chart';
import IconNotification from 'cccisd-icons/notification';
import style from './style.css';

const OverallPercent = props => {
    const [display, setDisplay] = useState('smiley');
    const { adherence } = props.data;
    const { targetScore } = props.settings;
    const { colors } = props;

    const _getActiveColor = displayType => {
        if (colors) {
            if (display === 'chart' && displayType === 'chart') {
                return {
                    backgroundColor: Color(colors.primary).lighten(0.1),
                };
            }

            if (display === 'smiley' && displayType === 'smiley') {
                return {
                    backgroundColor: Color(colors.primary).lighten(0.1),
                };
            }
            return { color: colors.primary };
        }

        return {};
    };

    const _renderTargetInfo = () => {
        const target = targetScore ? targetScore : props.data.target;
        if (!adherence || !target) {
            return <div>No data found</div>;
        }
        let icon = <IconSmile spaceRight />;
        let color = '#3673b6';
        let targetText;
        if (adherence > target) {
            targetText = 'Above Target';
        } else if (adherence === target) {
            targetText = 'At Target';
        } else {
            icon = <IconSad spaceRight />;
            color = '#d03916';
            targetText = 'Below Target';
        }
        return (
            <>
                <div className={style.bigScore} style={{ color }}>
                    {icon} {adherence}%
                </div>
                <div className={style.targetIndicator} style={{ color }}>
                    <IconNotification /> {targetText}
                </div>
            </>
        );
    };

    return (
        <div className={style.wrapper}>
            <div className={style.left}>
                {display === 'smiley' ? (
                    <>{_renderTargetInfo()}</>
                ) : (
                    <div>
                        <ProgressRing value={adherence} total={100} radius={40} />
                    </div>
                )}
            </div>
            <BrowserOnly>
                <div
                    className={style.right}
                    style={
                        colors
                            ? {
                                  borderLeft: `1px solid ${colors.primary}`,
                              }
                            : {}
                    }
                >
                    <Tooltip title="Chart view">
                        <div
                            className={display === 'chart' ? style.active : style.inactive}
                            onClick={() => setDisplay('chart')}
                            style={_getActiveColor('chart')}
                        >
                            <IconPieChart />
                        </div>
                    </Tooltip>
                    <Tooltip title="Icon view">
                        <div
                            className={display === 'smiley' ? style.active : style.inactive}
                            onClick={() => setDisplay('smiley')}
                            style={_getActiveColor('smiley')}
                        >
                            <IconSmile />
                        </div>
                    </Tooltip>
                </div>
            </BrowserOnly>
        </div>
    );
};

export default OverallPercent;
