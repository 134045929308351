export const generateNewId = ({ prefix = 'new', settings = [] }) => {
    if (!settings.find(item => item.id === prefix)) {
        return prefix;
    }

    let counter = 1;
    // eslint-disable-next-line no-loop-func
    while (settings.find(item => item.id === `${prefix}${counter}`)) {
        counter++;
    }

    return `${prefix}${counter}`;
};
