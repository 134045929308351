import React from 'react';
import PropTypes from 'prop-types';
import tableBuilderQuery from './tableBuilderQuery.graphql';
import TableBuilder from './TableBuilder';
import style from './style.css';

const LogTable = props => {
    const { title, description, show, columns, query } = props.settings;

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{title}</span>
                    </div>
                    <div style={{ marginBottom: '0.5em' }}>{description}</div>
                    <TableBuilder
                        columns={columns}
                        isPreview={props.isPreview}
                        query={props.isPreview ? tableBuilderQuery : query}
                    />
                </div>
            )}
        </>
    );
};

LogTable.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

LogTable.defaultProps = {
    filters: {},
};

export default LogTable;
