import React from 'react';
import PropTypes from 'prop-types';
import { NavBar } from 'cccisd-laravel-boilerplate';
import style from './style.css';

export default class Component extends React.Component {
    static propTypes = {
        className: PropTypes.string,
    };

    static defaultProps = {
        className: 'container',
    };

    render() {
        return (
            <NavBar
                className={this.props.className}
                logo={
                    <div>
                        <div className={style.logo} />
                        <div
                            style={{
                                position: 'relative',
                                left: '207px',
                                fontSize: '16px',
                                fontFamily: 'PT Sans,sans-serif',
                                fontWeight: 'bold',
                            }}
                        >
                            Strong African American Families
                        </div>
                    </div>
                }
            />
        );
    }
}
