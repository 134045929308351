import React from 'react';
import PropTypes from 'prop-types';
import summaryTableQuery from './overallFidelity.graphql';
import SummaryTable from './components/SummaryTable';
import style from './style.css';

const FidelitySummaryTable = props => {
    const { title, description, show, targetScore } = props.settings;
    const { isPreview, query } = props;

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{title}</span>
                    </div>
                    <div style={{ marginBottom: '0.5em' }}>{description}</div>
                    <SummaryTable
                        query={isPreview ? summaryTableQuery : query}
                        isPreview={props.isPreview}
                        filters={props.filters}
                        targetScore={targetScore}
                    />
                </div>
            )}
        </>
    );
};

FidelitySummaryTable.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

FidelitySummaryTable.defaultProps = {
    filters: {},
};

export default FidelitySummaryTable;
