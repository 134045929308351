import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import moment from 'moment';
import Table from 'cccisd-graphql-table';
import { BrowserOnly } from 'cccisd-laravel-report';
// import mockClient from '../_mockApolloClient';
import IconComment from 'cccisd-icons/bubble-dots3';
import IconPlus from 'cccisd-icons/plus2';
import IconMinus from 'cccisd-icons/minus2';
import IconMenu from 'cccisd-icons/menu7';
import IconSpinner from 'cccisd-icons/spinner8';
import style from './style.css';

const Comment = props => {
    const {
        groupName,
        sessionDate,
        teenComment,
        caregiverComment,
        familyComment,
        checklistFacilitator1Comment,
        checklistFacilitator2Comment,
        checklistFacilitator3Comment,
    } = props;

    if (
        teenComment ||
        caregiverComment ||
        familyComment ||
        checklistFacilitator1Comment ||
        checklistFacilitator2Comment ||
        checklistFacilitator3Comment
    ) {
        return (
            <div className={style.commentWrapper}>
                <div className={style.info}>
                    <span style={{ fontWeight: 'bold' }}>{groupName}</span>{' '}
                    {moment(sessionDate).format('MMM Do YYYY')}
                </div>
                <div className={style.commentBlocks}>
                    {teenComment && (
                        <div className={style.commentBlock}>
                            <div className={style.commentHeading}>Youth Adherence Comment</div>
                            <div className={style.comment}>{teenComment}</div>
                        </div>
                    )}
                    {caregiverComment && (
                        <div className={style.commentBlock}>
                            <div className={style.commentHeading}>Caregiver Adherence Comment</div>
                            <div className={style.comment}>{caregiverComment}</div>
                        </div>
                    )}
                    {familyComment && (
                        <div className={style.commentBlock}>
                            <div className={style.commentHeading}>Family Adherence Comment</div>
                            <div className={style.comment}>{familyComment}</div>
                        </div>
                    )}
                    {checklistFacilitator1Comment && (
                        <div className={style.commentBlock}>
                            <div className={style.commentHeading}>
                                Checklist Facilitator 1 Comment
                            </div>
                            <div className={style.comment}>{checklistFacilitator1Comment}</div>
                        </div>
                    )}
                    {checklistFacilitator2Comment && (
                        <div className={style.commentBlock}>
                            <div className={style.commentHeading}>
                                Checklist Facilitator 2 Comment
                            </div>
                            <div className={style.comment}>{checklistFacilitator2Comment}</div>
                        </div>
                    )}
                    {checklistFacilitator3Comment && (
                        <div className={style.commentBlock}>
                            <div className={style.commentHeading}>
                                Checklist Facilitator 3 Comment
                            </div>
                            <div className={style.comment}>{checklistFacilitator3Comment}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
    return null;
};

const Session = props => {
    const { sessionData, colors } = props;

    const _renderComments = () =>
        sessionData.map(c => (
            <Comment
                key={c.teenComment + c.sessionDate}
                groupName={c.groupName}
                sessionDate={c.sessionDate}
                teenComment={c.teenComment}
                caregiverComment={c.caregiverComment}
                familyComment={c.familyComment}
                checklistFacilitator1Comment={c.checklistFacilitator1Comment}
                checklistFacilitator2Comment={c.checklistFacilitator2Comment}
                checklistFacilitator3Comment={c.checklistFacilitator3Comment}
                colors={colors}
            />
        ));

    return (
        <div className={style.sessionWrapper}>
            <div className={style.head} style={colors ? { backgroundColor: colors.primary } : {}}>
                Session {sessionData[0].session}
            </div>
            <div className={style.comments}>{_renderComments()}</div>
        </div>
    );
};

const Component = props => {
    const [boxOpen, setBoxOpen] = useState(true);

    const { title } = props.settings;
    const { colors } = props;

    const _renderCommentBox = data => {
        let sessions = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
        };
        data.forEach(group => {
            group.sessionData.forEach(sd => {
                if (
                    sd.teenComment ||
                    sd.caregiverComment ||
                    sd.familyComment ||
                    sd.checklistFacilitator1Comment ||
                    sd.checklistFacilitator2Comment ||
                    sd.checklistFacilitator3Comment
                ) {
                    sessions[sd.session].push(sd);
                }
            });
        });
        return Object.keys(sessions)
            .map(key => {
                if (sessions[key].length > 0) {
                    return (
                        <Session
                            key={'session' + key}
                            sessionData={sessions[key]}
                            colors={colors}
                        />
                    );
                }
                return null;
            })
            .filter(d => d !== null);
    };

    const renderComments = tableProps => {
        if (!tableProps.loading) {
            return (
                <div>
                    <BrowserOnly>
                        <div className="btn-group" style={{ marginBottom: '0.7em' }}>
                            <button
                                type="button"
                                className={`btn btn-sm btn-default dropdown-toggle ${
                                    style.multipurposeButton
                                } ${tableProps.loading ? style.rotating : ''}`}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {tableProps.loading ? <IconSpinner /> : <IconMenu />}
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a href="" onClick={tableProps.loadData}>
                                        Reload data
                                    </a>
                                </li>
                                <li>
                                    <a href="" onClick={tableProps.downloadCsv}>
                                        Download CSV
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </BrowserOnly>
                    <div
                        className={style.wrapper}
                        style={colors ? { borderColor: colors.primary } : {}}
                    >
                        <div
                            className={style.header}
                            style={colors ? { backgroundColor: colors.primary } : {}}
                        >
                            <div>
                                <IconComment spaceRight />
                                {title}
                            </div>
                            <BrowserOnly>
                                <button
                                    type="button"
                                    className={`btn btn-sm ${style.menuButton}`}
                                    onClick={() => setBoxOpen(!boxOpen)}
                                    style={
                                        colors
                                            ? { backgroundColor: Color(colors.primary).darken(0.2) }
                                            : {}
                                    }
                                >
                                    {boxOpen ? <IconMinus /> : <IconPlus />}
                                </button>
                            </BrowserOnly>
                        </div>
                        {boxOpen
                            ? _renderCommentBox(props.convertDataStructure(tableProps.data))
                            : null}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <Table
            graphqlVariables={{
                groupIds: props.filters.groupIds,
                startDate: props.filters.startDate,
                endDate: props.filters.endDate,
            }}
            query={props.query}
            columns={[
                { name: 'group.label', label: 'Group Label' },
                {
                    name: 'childRoles.metricspawn.session1.devTags.YouthSessionFidelityComments',
                    label: 'Session 1 Youth Adherence Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session1.devTags.CaregiverSessionFidelityComments',
                    label: 'Session 1 Caregiver Adherence Comments',
                },
                {
                    name: 'childRoles.metricspawn.session1.devTags.FamilySessionFidelityComments',
                    label: 'Session 1 Family Adherence Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session1.devTags.FidelityChecklistFacilitator1Comments',
                    label: 'Session 1 Checklist Facilitator 1 Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session1.devTags.FidelityChecklistFacilitator2Comments',
                    label: 'Session 1 Checklist Facilitator 2 Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session1.devTags.FidelityChecklistFacilitator3Comments',
                    label: 'Session 1 Checklist Facilitator 3 Comments',
                },
                {
                    name: 'childRoles.metricspawn.session2.devTags.YouthSessionFidelityComments',
                    label: 'Session 2 Youth Adherence Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session2.devTags.CaregiverSessionFidelityComments',
                    label: 'Session 2 Caregiver Adherence Comments',
                },
                {
                    name: 'childRoles.metricspawn.session2.devTags.FamilySessionFidelityComments',
                    label: 'Session 2 Family Adherence Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session2.devTags.FidelityChecklistFacilitator1Comments',
                    label: 'Session 2 Checklist Facilitator 1 Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session2.devTags.FidelityChecklistFacilitator2Comments',
                    label: 'Session 2 Checklist Facilitator 2 Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session2.devTags.FidelityChecklistFacilitator3Comments',
                    label: 'Session 2 Checklist Facilitator 3 Comments',
                },
                {
                    name: 'childRoles.metricspawn.session3.devTags.YouthSessionFidelityComments',
                    label: 'Session 3 Youth Adherence Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session3.devTags.CaregiverSessionFidelityComments',
                    label: 'Session 3 Caregiver Adherence Comments',
                },
                {
                    name: 'childRoles.metricspawn.session3.devTags.FamilySessionFidelityComments',
                    label: 'Session 3 Family Adherence Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session3.devTags.FidelityChecklistFacilitator1Comments',
                    label: 'Session 3 Checklist Facilitator 1 Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session3.devTags.FidelityChecklistFacilitator2Comments',
                    label: 'Session 3 Checklist Facilitator 2 Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session3.devTags.FidelityChecklistFacilitator3Comments',
                    label: 'Session 3 Checklist Facilitator 3 Comments',
                },
                {
                    name: 'childRoles.metricspawn.session4.devTags.YouthSessionFidelityComments',
                    label: 'Session 4 Youth Adherence Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session4.devTags.CaregiverSessionFidelityComments',
                    label: 'Session 4 Caregiver Adherence Comments',
                },
                {
                    name: 'childRoles.metricspawn.session4.devTags.FamilySessionFidelityComments',
                    label: 'Session 4 Family Adherence Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session4.devTags.FidelityChecklistFacilitator1Comments',
                    label: 'Session 4 Checklist Facilitator 1 Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session4.devTags.FidelityChecklistFacilitator2Comments',
                    label: 'Session 4 Checklist Facilitator 2 Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session4.devTags.FidelityChecklistFacilitator3Comments',
                    label: 'Session 4 Checklist Facilitator 3 Comments',
                },
                {
                    name: 'childRoles.metricspawn.session5.devTags.YouthSessionFidelityComments',
                    label: 'Session 5 Youth Adherence Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session5.devTags.CaregiverSessionFidelityComments',
                    label: 'Session 5 Caregiver Adherence Comments',
                },
                {
                    name: 'childRoles.metricspawn.session5.devTags.FamilySessionFidelityComments',
                    label: 'Session 5 Family Adherence Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session5.devTags.FidelityChecklistFacilitator1Comments',
                    label: 'Session 5 Checklist Facilitator 1 Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session5.devTags.FidelityChecklistFacilitator2Comments',
                    label: 'Session 5 Checklist Facilitator 2 Comments',
                },
                {
                    name:
                        'childRoles.metricspawn.session5.devTags.FidelityChecklistFacilitator3Comments',
                    label: 'Session 5 Checklist Facilitator 3 Comments',
                },
            ]}
            render={renderComments}
        />
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    data: PropTypes.array,
    query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    colors: PropTypes.object,
    convertDataStructure: PropTypes.func,
    filters: PropTypes.object,
};

Component.defaultProps = {
    convertDataStructure: data => {
        // console.log(data);
        let convertedData = [];
        if (data.length > 0) {
            convertedData = data.map(group => {
                return {
                    id: group['group.groupId'],
                    groupName: group['group.label'],
                    sessionData: [
                        {
                            id: group['group.groupId'],
                            groupName: group['group.label'],
                            session: 1,
                            sessionDate: group['childRoles.metricspawn.session1.substr'],
                            teenComment:
                                group[
                                    'childRoles.metricspawn.session1.devTags.YouthSessionFidelityComments'
                                ],
                            caregiverComment:
                                group[
                                    'childRoles.metricspawn.session1.devTags.CaregiverSessionFidelityComments'
                                ],
                            familyComment:
                                group[
                                    'childRoles.metricspawn.session1.devTags.FamilySessionFidelityComments'
                                ],
                            checklistFacilitator1Comment:
                                group[
                                    'childRoles.metricspawn.session1.devTags.FidelityChecklistFacilitator1Comments'
                                ],
                            checklistFacilitator2Comment:
                                group[
                                    'childRoles.metricspawn.session1.devTags.FidelityChecklistFacilitator2Comments'
                                ],
                            checklistFacilitator3Comment:
                                group[
                                    'childRoles.metricspawn.session1.devTags.FidelityChecklistFacilitator3Comments'
                                ],
                        },
                        {
                            id: group['group.groupId'],
                            groupName: group['group.label'],
                            session: 2,
                            sessionDate: group['childRoles.metricspawn.session2.substr'],
                            teenComment:
                                group[
                                    'childRoles.metricspawn.session2.devTags.YouthSessionFidelityComments'
                                ],
                            caregiverComment:
                                group[
                                    'childRoles.metricspawn.session2.devTags.CaregiverSessionFidelityComments'
                                ],
                            familyComment:
                                group[
                                    'childRoles.metricspawn.session2.devTags.FamilySessionFidelityComments'
                                ],
                            checklistFacilitator1Comment:
                                group[
                                    'childRoles.metricspawn.session2.devTags.FidelityChecklistFacilitator1Comments'
                                ],
                            checklistFacilitator2Comment:
                                group[
                                    'childRoles.metricspawn.session2.devTags.FidelityChecklistFacilitator2Comments'
                                ],
                            checklistFacilitator3Comment:
                                group[
                                    'childRoles.metricspawn.session2.devTags.FidelityChecklistFacilitator3Comments'
                                ],
                        },
                        {
                            id: group['group.groupId'],
                            groupName: group['group.label'],
                            session: 3,
                            sessionDate: group['childRoles.metricspawn.session3.substr'],
                            teenComment:
                                group[
                                    'childRoles.metricspawn.session3.devTags.YouthSessionFidelityComments'
                                ],
                            caregiverComment:
                                group[
                                    'childRoles.metricspawn.session3.devTags.CaregiverSessionFidelityComments'
                                ],
                            familyComment:
                                group[
                                    'childRoles.metricspawn.session3.devTags.FamilySessionFidelityComments'
                                ],
                            checklistFacilitator1Comment:
                                group[
                                    'childRoles.metricspawn.session3.devTags.FidelityChecklistFacilitator1Comments'
                                ],
                            checklistFacilitator2Comment:
                                group[
                                    'childRoles.metricspawn.session3.devTags.FidelityChecklistFacilitator2Comments'
                                ],
                            checklistFacilitator3Comment:
                                group[
                                    'childRoles.metricspawn.session3.devTags.FidelityChecklistFacilitator3Comments'
                                ],
                        },
                        {
                            id: group['group.groupId'],
                            groupName: group['group.label'],
                            session: 4,
                            sessionDate: group['childRoles.metricspawn.session4.substr'],
                            teenComment:
                                group[
                                    'childRoles.metricspawn.session4.devTags.YouthSessionFidelityComments'
                                ],
                            caregiverComment:
                                group[
                                    'childRoles.metricspawn.session4.devTags.CaregiverSessionFidelityComments'
                                ],
                            familyComment:
                                group[
                                    'childRoles.metricspawn.session4.devTags.FamilySessionFidelityComments'
                                ],
                            checklistFacilitator1Comment:
                                group[
                                    'childRoles.metricspawn.session4.devTags.FidelityChecklistFacilitator1Comments'
                                ],
                            checklistFacilitator2Comment:
                                group[
                                    'childRoles.metricspawn.session4.devTags.FidelityChecklistFacilitator2Comments'
                                ],
                            checklistFacilitator3Comment:
                                group[
                                    'childRoles.metricspawn.session4.devTags.FidelityChecklistFacilitator3Comments'
                                ],
                        },
                        {
                            id: group['group.groupId'],
                            groupName: group['group.label'],
                            session: 5,
                            sessionDate: group['childRoles.metricspawn.session5.substr'],
                            teenComment:
                                group[
                                    'childRoles.metricspawn.session5.devTags.YouthSessionFidelityComments'
                                ],
                            caregiverComment:
                                group[
                                    'childRoles.metricspawn.session5.devTags.CaregiverSessionFidelityComments'
                                ],
                            familyComment:
                                group[
                                    'childRoles.metricspawn.session5.devTags.FamilySessionFidelityComments'
                                ],
                            checklistFacilitator1Comment:
                                group[
                                    'childRoles.metricspawn.session5.devTags.FidelityChecklistFacilitator1Comments'
                                ],
                            checklistFacilitator2Comment:
                                group[
                                    'childRoles.metricspawn.session5.devTags.FidelityChecklistFacilitator2Comments'
                                ],
                            checklistFacilitator3Comment:
                                group[
                                    'childRoles.metricspawn.session5.devTags.FidelityChecklistFacilitator3Comments'
                                ],
                        },
                    ],
                };
            });
        } else {
            convertedData = [
                {
                    id: 10,
                    groupName: 'My Group',
                    sessionData: [
                        {
                            id: 10,
                            groupName: 'My Group',
                            session: 1,
                            sessionDate: '2020-10-01',
                            teenComment: 'Youth Comment Session 1',
                            caregiverComment: 'Caregiver comment session 1',
                            familyComment: 'Family adherence comment session 1',
                            checklistFacilitator1Comment: 'Checklist Facilitator 1',
                            checklistFacilitator2Comment: 'Checklist Facilitator 2',
                            checklistFacilitator3Comment: 'Checklist Facilitator 3',
                        },
                        {
                            id: 10,
                            groupName: 'My Group',
                            session: 2,
                            sessionDate: '2020-10-08',
                            teenComment: 'Youth comment session 2',
                            caregiverComment: 'caregiver comment session 2',
                            familyComment: 'Family adherence comment session 2',
                            checklistFacilitator1Comment: 'Checklist Facilitator 1',
                            checklistFacilitator2Comment: 'Checklist Facilitator 2',
                            checklistFacilitator3Comment: 'Checklist Facilitator 3',
                        },
                    ],
                },
            ];
        }
        return convertedData;
    },
};

export default Component;
