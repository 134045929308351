import React from 'react';
import Table from 'cccisd-graphql-table';
import _round from 'lodash/round';

const SummaryTable = props => {
    const columns = [
        { name: 'group.label', label: 'Group', sort: true },
        {
            name: 'childRoles.participantSummary.youthPre',
            label: 'Youth Pre',
            className: 'text-center',
            render: ({ value }) => _round(value, 1),
        },
        {
            name: 'childRoles.participantSummary.youthPost',
            label: 'Youth Post',
            className: 'text-center',
            render: ({ value }) => _round(value, 1),
        },
        {
            name: 'childRoles.participantSummary.caregiverPre',
            label: 'Caregiver Pre',
            className: 'text-center',
            render: ({ value }) => _round(value, 1),
        },
        {
            name: 'childRoles.participantSummary.caregiverPost',
            label: 'Caregiver Post',
            className: 'text-center',
            render: ({ value }) => _round(value, 1),
        },
    ];

    return (
        <Table
            rowKey="group.groupId"
            graphqlVariables={{
                groupIds: props.filters.groupIds,
                startDate: props.filters.startDate,
                endDate: props.filters.endDate,
            }}
            query={props.query}
            columns={columns}
            // render={tableProps => console.log(tableProps)}
        />
    );
};

export default SummaryTable;
