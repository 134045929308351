import outcomesGCSummaryTable from './widgets/outcomesGCSummaryTable';
import outcomesSummaryCallouts from './widgets/outcomesSummaryCallouts';
import outcomesSummaryChart from './widgets/outcomesSummaryChart';
import outcomesGroupComments from './widgets/outcomesGroupComments';
import outcomesGCGroupCharts from './widgets/outcomesGCGroupCharts';

export default [
    outcomesSummaryCallouts,
    outcomesSummaryChart,
    outcomesGroupComments,
    outcomesGCSummaryTable,
    outcomesGCGroupCharts,
];
