/* eslint-disable no-restricted-globals */
import React from 'react';
import _isNil from 'lodash/isNil';

/*
  Displays all the  facilitators used for this group across all sessions, with no repeats
*/

export default settings => {
    return ({ value, row }) => {
        if (value === null) {
            return '';
        }
        const count = value.length;

        return (
            <div>
                {value.map((fac, index) => {
                    if (!_isNil(fac.value)) {
                        return (
                            <div>
                                <ul
                                    style={{
                                        listStyle: 'none',
                                        paddingLeft: '0',
                                    }}
                                >
                                    <li>
                                        {fac.value}
                                        {count === 1 || index === count - 1 ? '' : ','}
                                    </li>
                                </ul>
                            </div>
                        );
                    }

                    return '';
                })}
            </div>
        );
    };
};
