import React from 'react';
import PropTypes from 'prop-types';
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import NavBar from 'js/components/NavBar';
import { Nav as SecondaryNav } from 'cccisd-laravel-appdefs';
import Footer from 'cccisd-footer';
import style from './style.css';

var AppDefs = window.cccisd.appDefs;
var Fortress = window.cccisd.fortress;
// import { ClientToolbar } from 'cccisd-impact';
// import { connect } from 'react-redux';
// import { buildClientData, changeClient } from '../../reducers/cbits';

// const mapStateToProps = (state, props) => {
//     return {
//         loading: state.app.cbits.loading,
//         clients: state.app.cbits.clients,
//         currentClient: state.app.cbits.currentClient,
//     };
// };

export default class NewLayout extends React.Component {
    static propTypes = {
        children: PropTypes.node,

        /**
         * Build Initial data
         *
         */
        buildClientData: PropTypes.func,

        /**
         * active clients
         */
        clients: PropTypes.array,

        /**
         * current client
         */
        currentClient: PropTypes.object,

        /**
         * loading state
         */
        loading: PropTypes.bool,

        /**
         * change client in redux
         */
        changeClient: PropTypes.func,
    };

    render() {
        return (
            <div className={style.body}>
                <div className={style.content}>
                    <NavBar className="container-fluid" />
                    {Fortress.auth() && (
                        <div>
                            <SecondaryNav
                                className="container-fluid"
                                navs={AppDefs.navs}
                                routes={AppDefs.routes}
                            />
                        </div>
                    )}

                    <div>{this.props.children}</div>
                </div>
                <div className={style.footer}>
                    <Footer className="container-fluid" />
                </div>
            </div>
        );
    }
}
