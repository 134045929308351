import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'logAttendanceTracker',
    label: 'Attendance Tracker',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
