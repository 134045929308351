import logTableQuery from './logTableQuery.graphql';

export default {
    query: async ({ query }) => {
        await new Promise(resolve => setTimeout(resolve, 1000));

        if (query === logTableQuery) {
            return {
                data: {
                    userList: [
                        {
                            id: 1,
                            name: 'MB0101',
                            attended: 5,
                            participated: 2,
                            behaviorProblems: 0,
                            redirection: 0,
                            skills: 2,
                        },
                        {
                            id: 2,
                            name: 'MB0102',
                            attended: 2,
                            participated: 2,
                            behaviorProblems: 0,
                            redirection: 2,
                            skills: 0,
                        },
                        {
                            id: 3,
                            name: 'MB0103',
                            attended: 5,
                            participated: 2,
                            behaviorProblems: 2,
                            redirection: 0,
                            skills: 0,
                        },
                    ],
                    totalCount: 5,
                    filteredCount: 5,
                },
            };
        }

        return {
            data: {},
        };
    },
};
