import React from 'react';
import PropTypes from 'prop-types';
import Table from 'cccisd-graphql-table';
import IconMenu from 'cccisd-icons/menu7';
import IconSpinner from 'cccisd-icons/spinner8';
import { BrowserOnly } from 'cccisd-laravel-report';
import IconCheck from 'cccisd-icons/checkmark';
// import mockClient from '../_mockApolloClient.js';
import style from './style.css';

const AttendanceTable = props => {
    const multipleGroupsSelected = true;
    const { colors } = props;

    const _renderSessionCols = (fill, fillVal) => {
        // fill = array of cell indexes to fill
        // fillVal = value to fill in these cells
        const sessionCols = [];

        for (let i = 1; i <= props.totalSessions; i++) {
            let cellVal;
            if (fill) {
                if (fill.includes(i)) {
                    cellVal = fillVal;
                }
            } else {
                cellVal = i;
            }
            sessionCols.push(<td key={i}>{cellVal}</td>);
        }
        return sessionCols;
    };

    const _renderTableBody = data => {
        if (data.length < 1) {
            return (
                <tr>
                    <td colSpan={props.totalSessions * 2 + 4}>
                        No data has been entered for the selected groups.
                    </td>
                </tr>
            );
        }
        return data.map(group => {
            return (
                <React.Fragment key={group.groupName}>
                    {multipleGroupsSelected && (
                        <tr>
                            <th rowSpan={group.participants.length + 1}>{group.groupName}</th>
                        </tr>
                    )}
                    {group.participants.map(participant => {
                        return (
                            <tr key={participant.id}>
                                <td>{participant.name}</td>
                                {_renderSessionCols(
                                    participant.attended,
                                    <span style={colors ? { color: colors.primary } : {}}>
                                        <IconCheck />
                                    </span>
                                )}
                                <td className={style.tableHeadingAlt}>
                                    {Math.round(participant.youthAttendanceRate * 100)}%
                                </td>
                                {_renderSessionCols(
                                    participant.caregiverAttended,
                                    <span style={colors ? { color: colors.primary } : {}}>
                                        <IconCheck />
                                    </span>
                                )}
                                <td className={style.tableHeadingAlt}>
                                    {Math.round(participant.caregiverAttendanceRate * 100)}%
                                </td>
                            </tr>
                        );
                    })}
                </React.Fragment>
            );
        });
    };

    const renderTable = tableProps => {
        if (tableProps.data.length > 0 && !tableProps.loading) {
            // console.log(tableProps.data);
            return (
                <div>
                    <BrowserOnly>
                        <div className="btn-group" style={{ marginBottom: '0.7em' }}>
                            <button
                                type="button"
                                className={`btn btn-sm btn-default dropdown-toggle ${
                                    style.multipurposeButton
                                } ${tableProps.loading ? style.rotating : ''}`}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {tableProps.loading ? <IconSpinner /> : <IconMenu />}
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a href="" onClick={tableProps.loadData}>
                                        Reload data
                                    </a>
                                </li>
                                <li>
                                    <a href="" onClick={tableProps.downloadCsv}>
                                        Download CSV
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </BrowserOnly>
                    <table className={style.table}>
                        <thead>
                            <tr>
                                {multipleGroupsSelected && (
                                    <th
                                        className={style.tableHeading}
                                        rowSpan="2"
                                        style={colors ? { backgroundColor: colors.primary } : {}}
                                    >
                                        Group ID
                                    </th>
                                )}
                                <th
                                    className={style.tableHeading}
                                    rowSpan="2"
                                    style={colors ? { backgroundColor: colors.primary } : {}}
                                >
                                    Participant ID
                                </th>
                                <th colSpan={props.totalSessions} className={style.tableHeadingAlt}>
                                    <span>Session Number</span>
                                </th>
                                <th
                                    rowSpan="2"
                                    className={style.tableHeading}
                                    style={colors ? { backgroundColor: colors.primary } : {}}
                                >
                                    <span>Youth Attendance Rate</span>
                                </th>
                                <th colSpan={props.totalSessions} className={style.tableHeadingAlt}>
                                    <span>Session Number</span>
                                </th>
                                <th
                                    rowSpan="2"
                                    className={style.tableHeading}
                                    style={colors ? { backgroundColor: colors.primary } : {}}
                                >
                                    <span>Caregiver Attendance Rate</span>
                                </th>
                            </tr>
                            <tr>
                                {_renderSessionCols()}
                                {_renderSessionCols()}
                            </tr>
                        </thead>
                        <tbody>
                            {_renderTableBody(props.convertDataStructure(tableProps.data))}
                        </tbody>
                    </table>
                </div>
            );
        }
        return null;
    };

    return (
        <Table
            // apolloClient={props.isPreview ? mockClient : undefined}
            graphqlVariables={{ groupIds: props.filters.groupIds }}
            query={props.query}
            columns={[
                { name: 'group.label', label: 'Group Name' },
                {
                    name: 'childRoles.metricspawn.assignmentProgressSummary.caregiverAttendanceAvg',
                    label: 'Caregiver Attendance Average',
                },
                {
                    name: 'childRoles.metricspawn.assignmentProgressSummary.youthAttendanceAvg',
                    label: 'Youth Attendance Average',
                },
            ]}
            render={renderTable}
        />
    );
};

AttendanceTable.propTypes = {
    query: PropTypes.object,
    convertDataStructure: PropTypes.func,
    colors: PropTypes.object,
    isPreview: PropTypes.bool,
    totalSessions: PropTypes.number,
    filters: PropTypes.object,
};

AttendanceTable.defaultProps = {
    convertDataStructure: data => {
        const getSessionsAttended = (sessionData, node) => {
            let sessions = [];
            sessionData.forEach(session => {
                if (session.devTags[node] === '1') {
                    sessions.push(
                        Number(session.deployment.label[session.deployment.label.length - 1])
                    );
                }
            });
            return sessions;
        };

        let convertedData = [];
        if (data.length > 0) {
            convertedData = data.map(group => {
                if (group['group.groupId'] && group['childRoles.participantList'].length > 0) {
                    return {
                        groupId: group['group.groupId'],
                        groupName: group['group.label'],
                        sessionsCompleted: 5,
                        participants: group['childRoles.participantList'].map(participant => {
                            return {
                                id: participant.pawn.pawnId,
                                name: participant.fields.participantId,
                                attended: getSessionsAttended(
                                    participant.assignmentProgressList,
                                    'YouthSessionAttendance'
                                ),
                                caregiverAttended: getSessionsAttended(
                                    participant.assignmentProgressList,
                                    'CaregiverSessionAttendance'
                                ),
                                youthAttendanceRate:
                                    participant.assignmentProgressSummary.youthAttAvg,
                                caregiverAttendanceRate:
                                    participant.assignmentProgressSummary.caregiverAttAvg,
                            };
                        }),
                    };
                }
                return null;
            });
        } else {
            convertedData = [
                {
                    groupId: 0,
                    groupName: 'Group1',
                    sessionsCompleted: 5,
                    participants: [
                        {
                            id: 1,
                            name: 'MB0101',
                            attended: [1, 2, 3, 4, 5],
                            caregiverAttended: [1, 2, 3, 4, 5],
                            youthAttendanceRate: 1,
                            caregiverAttendanceRate: 1,
                        },
                        {
                            id: 2,
                            name: 'MB0102',
                            attended: [1, 2, 3, 4, 5],
                            caregiverAttended: [1, 2, 3, 4],
                            youthAttendanceRate: 1,
                            caregiverAttendanceRate: 0.8,
                        },
                        {
                            id: 3,
                            name: 'MB0103',
                            attended: [1, 2, 4, 5],
                            caregiverAttended: [1, 2, 4, 5],
                            youthAttendanceRate: 1,
                            caregiverAttendanceRate: 1,
                        },
                    ],
                },
                {
                    groupId: 1,
                    groupName: 'Group2',
                    sessionsCompleted: 5,
                    participants: [
                        {
                            id: 4,
                            name: 'MB0104',
                            attended: [1, 2, 3, 4, 5],
                            caregiverAttended: [1, 2, 3, 4, 5],
                            youthAttendanceRate: 1,
                            caregiverAttendanceRate: 1,
                        },
                        {
                            id: 5,
                            name: 'MB0105',
                            attended: [1, 2, 3, 4, 5],
                            caregiverAttended: [1, 2, 3, 4],
                            youthAttendanceRate: 1,
                            caregiverAttendanceRate: 0.8,
                        },
                        {
                            id: 6,
                            name: 'MB0106',
                            attended: [1, 2, 4, 5],
                            caregiverAttended: [1, 2, 4, 5],
                            youthAttendanceRate: 1,
                            caregiverAttendanceRate: 1,
                        },
                    ],
                },
            ];
        }
        // console.log({ convertedData });
        const filteredData = convertedData
            .filter(d => d !== null)
            .filter(i => i.participants.length > 0);

        // console.log('from base-packages', filteredData);
        return filteredData;
    },
};

export default AttendanceTable;
