import React from 'react';
import PropTypes from 'prop-types';
import Info from './Info';

const Component = props => {
    return (
        <>
            {props.settings.show && (
                <div style={{ marginBottom: '2em' }}>
                    <Info
                        colors={props.settings.colors}
                        title={props.settings.title}
                        content={props.settings.description}
                    />
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
