import React from 'react';
import PropTypes from 'prop-types';
import adherenceTableQuery from './adherenceQuery.graphql';
import Table from './components/Table';
import style from './style.css';

const FidelityComponentsChart = props => {
    // const fakeData = props.isPreview
    //     ? [
    //           {
    //               id: 1,
    //               groupName: 'Group 1 (4)',
    //               currentSession: 'Session 5',
    //               lastSession: '5/10/19',
    //               teenAdherence: 70,
    //               caregiverAdherence: 53,
    //               familyAdherence: 61,
    //           },
    //           {
    //               id: 2,
    //               groupName: 'Group 2 (5)',
    //               currentSession: 'Session 5',
    //               lastSession: '5/10/19',
    //               teenAdherence: 34,
    //               caregiverAdherence: 70,
    //               familyAdherence: 90,
    //           },
    //           {
    //               id: 3,
    //               groupName: 'Group 3 (5)',
    //               currentSession: 'Session 3',
    //               lastSession: '5/10/19',
    //               teenAdherence: 85,
    //               caregiverAdherence: 70,
    //               familyAdherence: 90,
    //           },
    //       ]
    //     : null;

    const { title, description, show, targetScore } = props.settings;
    const { isPreview, query } = props;

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{title}</span>
                    </div>
                    <div style={{ marginBottom: '0.5em' }}>{description}</div>
                    <Table
                        query={isPreview ? adherenceTableQuery : query}
                        isPreview={props.isPreview}
                        filters={props.filters}
                        targetScore={targetScore}
                    />
                </div>
            )}
        </>
    );
};

FidelityComponentsChart.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

FidelityComponentsChart.defaultProps = {
    filters: {},
};

export default FidelityComponentsChart;
