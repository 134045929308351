import React from 'react';
import { Field, CccisdToggle, CccisdInput } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    title: 'Fidelity Adherence Table',
    description: 'By Group',
    targetScore: 80,
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Adherence Table?" />
            <Field name="title" component={CccisdInput} label="Title" />
            <Field name="description" component={CccisdInput} label="Description" />
            <Field name="targetScore" component={CccisdInput} label="Target Score" type="number" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
