import React from 'react';
import { Field, CccisdToggle, CccisdInput } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    title: 'Facilitator Feedback Score',
    description: 'Comparison across sessions',
    chart: {
        xLabel: 'Session',
        yLabel: 'Score',
        showLegend: true,
        targetLineValue: '',
        verticalScaleMin: '0',
        verticalScaleMax: '6',
        scoreSuffix: '',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Facilitator Feedback Chart?" />
            <Field name="title" component={CccisdInput} label="Title" />
            <Field name="description" component={CccisdInput} label="Description" />
            <h4>Chart Settings</h4>
            <Field name="chart.xLabel" component={CccisdInput} label="X-axis Label" />
            <Field name="chart.yLabel" component={CccisdInput} label="Y-axis Label" />
            <Field name="chart.showLegend" component={CccisdToggle} label="Show Legend?" />
            <Field
                name="chart.targetLineValue"
                component={CccisdInput}
                label="Benchmark Value"
                type="number"
            />
            <Field
                name="chart.verticalScaleMin"
                component={CccisdInput}
                label="Y-axis Min Value"
                type="number"
            />
            <Field
                name="chart.verticalScaleMax"
                component={CccisdInput}
                label="Y-axis Max Value"
                type="number"
            />
            <Field name="chart.scoreSuffix" component={CccisdInput} label="Y-axis Suffix" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
