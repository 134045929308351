/* eslint-disable no-restricted-globals */
import React from 'react';
import _includes from 'lodash/includes';
import _isNil from 'lodash/isNil';
import _find from 'lodash/find';
import style from './style.css';

/*
 * Displays the facilitator eval scores on a new line
 */
export default settings => {
    return ({ value, row }) => {
        const currentSession = row['fields.currentSession'];
        if (settings.session > currentSession) {
            return '';
        }

        // response sets for each group session
        const sessionResponseSets = {
            1: [1, 2, 3],
            2: [4, 5, 6],
            3: [7, 8, 9],
            4: [10, 11, 12],
            5: [13, 14, 15],
        };

        let responseSetsForThisSession = sessionResponseSets[settings.session];
        let facilitatorData = [];

        row.metricsList.forEach((response, index) => {
            if (_includes(responseSetsForThisSession, response.responseSet)) {
                facilitatorData[index] = [];
                facilitatorData[index].name = response.devTags.FacilitatorEvaluationPawnName;
                facilitatorData[index].score = response.devTags.FacilitatorEvaluationScore;
            }
        });

        return (
            <div>
                {facilitatorData.map(facilitator => {
                    if (_isNil(facilitator.score)) {
                        return '';
                    }

                    let iconItem = _find(
                        settings.status,
                        item => facilitator.score >= item.min && facilitator.score <= item.max
                    );

                    return (
                        <div key={facilitator.pawn} className="text-center">
                            {iconItem && (
                                <span>
                                    <svg
                                        color={iconItem.icon.color}
                                        className={style.icon}
                                        xmins="http://www.w3.org/2000/svg"
                                        viewBox={iconItem.icon.viewBox}
                                        width="20"
                                        height="20"
                                    >
                                        <g>
                                            {iconItem.icon.paths.map(p => {
                                                return <path key={p} d={p} />;
                                            })}
                                        </g>
                                    </svg>
                                </span>
                            )}
                            <div>
                                {facilitator.name + ': ' || ''} {facilitator.score || ''}{' '}
                                {iconItem && ' '}
                            </div>
                        </div>
                    );
                })}
            </div>
        );
    };
};
