import outcomesSummaryTable from './widgets/outcomesSummaryTable';
import outcomesSummaryCallouts from './widgets/outcomesSummaryCallouts';
import outcomesSummaryChart from './widgets/outcomesSummaryChart';
import outcomesGroupComments from './widgets/outcomesGroupComments';
import outcomesParticipantCharts from './widgets/outcomesParticipantCharts';

export default [
    outcomesSummaryCallouts,
    outcomesSummaryChart,
    outcomesGroupComments,
    outcomesSummaryTable,
    outcomesParticipantCharts,
];
