import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    teen: {
        show: true,
        title: 'Youth Adherence',
        description: 'Average across all sessions',
        targetScore: 80,
    },
    caregiver: {
        show: true,
        title: 'Caregiver Adherence',
        description: 'Average across all sessions',
        targetScore: 80,
    },
    family: {
        show: true,
        title: 'Family Adherence',
        description: 'Average across all sessions',
        targetScore: 80,
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Components Callouts?" />
            <h4>Callout 1</h4>
            <Field name="teen.show" component={CccisdToggle} label="Show Callout 1?" />
            <Field name="teen.title" component={CccisdInput} label="Title" />
            <Field name="teen.description" component={CccisdInput} label="Description" />
            <Field
                name="teen.targetScore"
                component={CccisdInput}
                label="Target Adherence Score"
                type="number"
            />
            <h4>Callout 2</h4>
            <Field name="caregiver.show" component={CccisdToggle} label="Show Callout 2?" />
            <Field name="caregiver.title" component={CccisdInput} label="Title" />
            <Field name="caregiver.description" component={CccisdInput} label="Description" />
            <Field
                name="caregiver.targetScore"
                component={CccisdInput}
                label="Target Adherence Score"
                type="number"
            />
            <h4>Callout 3</h4>
            <Field name="family.show" component={CccisdToggle} label="Show Callout 3?" />
            <Field name="family.title" component={CccisdInput} label="Title" />
            <Field name="family.description" component={CccisdInput} label="Description" />
            <Field
                name="family.targetScore"
                component={CccisdInput}
                label="Target Adherence Score"
                type="number"
            />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
