import React from 'react';
import PropTypes from 'prop-types';
import { TwitterPicker } from 'react-color';
import { CccisdFieldWrapper } from 'cccisd-formik';
import style from './style.css';

class ColorPicker extends React.Component {
    static propTypes = {
        color: PropTypes.string,
        colors: PropTypes.array,
        field: PropTypes.object.isRequired, // Primary field represented by this component
        fields: PropTypes.array, // All fields that are controlled by this component
        label: PropTypes.string,
        onChange: PropTypes.func,
        form: PropTypes.object,
    };

    state = {
        displayColorPicker: false,
        color: this.props.color || this.props.field.value || '#fff',
        colors: this.props.colors || [
            '#B80000',
            '#b3451b',
            '#FCCB00',
            '#008B02',
            '#006B76',
            '#1273DE',
            '#3673b6',
            '#5300EB',
            '#EB9694',
            '#FAD0C3',
            '#FEF3BD',
            '#C1E1C5',
            '#BEDADC',
            '#fff',
            '#e1e1e1',
            '#000',
        ],
    };

    handleClick = () => {
        this.setState({ displayColorPicker: !this.state.displayColorPicker });
    };

    handleClose = () => {
        this.setState({ displayColorPicker: false });
    };

    handleChange = async color => {
        await this.setState({ color: color.hex, displayColorPicker: false });
        this.props.form.setFieldValue('colors.primary', this.state.color);
        if (this.props.fields) {
            this.props.fields.forEach(f => {
                f.onChange(this.state.color);
            });
        }
    };

    render() {
        // const { field, label, onChange } = this.props;
        return (
            <CccisdFieldWrapper
                field={this.props.field}
                form={this.props.form}
                label="Color Scheme"
            >
                <div>
                    <div className={style.swatch} onClick={this.handleClick}>
                        <div className={style.color} style={{ background: this.state.color }} />
                    </div>
                    {this.state.displayColorPicker ? (
                        <div className={style.popover}>
                            <div className={style.cover} onClick={this.handleClose} />
                            <TwitterPicker
                                colors={this.state.colors}
                                color={this.state.color}
                                onChange={this.handleChange}
                                width="212"
                            />
                        </div>
                    ) : null}
                </div>
            </CccisdFieldWrapper>
        );
    }
}

export default ColorPicker;
