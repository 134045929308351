import React from 'react';
import Table from 'cccisd-graphql-table';

const SummaryTable = props => {
    const columns = [
        { name: 'fields.participantId', label: 'Participant List', sort: true },
        {
            name: 'teenDataPre.devTags.YouthPreScore',
            label: 'Youth Pre',
            className: 'text-center',
        },
        {
            name: 'teenDataPost.devTags.YouthPostScore',
            label: 'Youth Post',
            className: 'text-center',
        },
        {
            name: 'caregiverDataPre.devTags.CaregiverPreScore',
            label: 'Caregiver Pre',
            className: 'text-center',
        },
        {
            name: 'caregiverDataPost.devTags.CaregiverPostScore',
            label: 'Caregiver Post',
            className: 'text-center',
        },
    ];

    return (
        <Table
            rowKey="pawn.pawnId"
            graphqlVariables={{
                groupIds: props.filters.groupIds,
                startDate: props.filters.startDate,
                endDate: props.filters.endDate,
            }}
            query={props.query}
            columns={columns}
            // render={tableProps => console.log(tableProps)}
        />
    );
};

export default SummaryTable;
