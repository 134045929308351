import outcomesIndividualSummaryTable from './widgets/outcomesIndividualSummaryTable';
import outcomesIndividualSummaryCallouts from './widgets/outcomesIndividualSummaryCallouts';
import outcomesIndividualSummaryChart from './widgets/outcomesIndividualSummaryChart';
import outcomesIndividualComments from './widgets/outcomesIndividualComments';
import recommendations from './widgets/recommendations';

export default [
    outcomesIndividualSummaryCallouts,
    outcomesIndividualSummaryChart,
    outcomesIndividualSummaryTable,
    outcomesIndividualComments,
    recommendations,
];
