import React from 'react';
import Color from 'color';
import { ResponsiveBar } from 'cccisd-nivo/bar';

const ComponentChartBar = props => {
    const {
        targetLineValue,
        verticalScaleMin,
        verticalScaleMax,
        scoreSuffix,
        showLegend,
        showLabels,
        colorScheme,
    } = props.settings;

    const getColors = () => {
        const { colors } = props;
        if (colors) {
            let myColors = [colors.primary];
            for (let i = 1; i < 7; i++) {
                myColors.push(
                    Color(colors.primary)
                        .lighten(0.15 * i)
                        .hex()
                );
            }
            return myColors;
        }
        return { scheme: 'category10' };
    };

    return (
        <div style={{ width: '100%', height: '300px', marginTop: '0.5em' }}>
            <ResponsiveBar
                data={props.data}
                colors={getColors()}
                colorBy={v => {
                    if (colorScheme === 'highlightAboveBenchmark') {
                        return v.value > targetLineValue;
                    }
                    return v.id;
                }}
                borderWidth={1}
                padding={0.2}
                groupMode="grouped"
                keys={[
                    'Session 1',
                    'Session 2',
                    'Session 3',
                    'Session 4',
                    'Session 5',
                    'Session 6',
                    'Session 7',
                ]}
                indexBy="metric"
                minValue={Number(verticalScaleMin) || 0}
                maxValue={Number(verticalScaleMax) || 100}
                axisLeft={{
                    format: n => `${n}${scoreSuffix}`,
                }}
                axisBottom={{
                    tickSize: 0,
                }}
                labelTextColor="#fff"
                labelFormat={value => `${value}${scoreSuffix}`}
                enableLabel={showLabels ? true : false}
                layers={
                    showLegend
                        ? ['grid', 'axes', 'bars', 'markers', 'legends', 'annotations']
                        : ['grid', 'axes', 'bars', 'markers', 'annotations']
                }
                markers={
                    targetLineValue
                        ? [
                              {
                                  axis: 'y',
                                  value: Number(targetLineValue) || 80,
                                  lineStyle: {
                                      stroke: '#b1cbe7',
                                      strokeWidth: 2,
                                      strokeDasharray: '10,5',
                                  },
                                  legend: '',
                                  legendOrientation: 'horizontal',
                              },
                          ]
                        : []
                }
            />
        </div>
    );
};

export default ComponentChartBar;
