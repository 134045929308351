import moment from 'moment';

export default [
    {
        name: 'startDate',
        label: 'Session Start Date',
        initialValue: moment(new Date())
            .subtract(1, 'y')
            .format('YYYY-MM-DD'),
        isRequired: true,
    },
    {
        name: 'endDate',
        label: 'Session End Date',
        initialValue: moment(new Date()).format('YYYY-MM-DD'),
        isRequired: true,
    },
    { name: 'groupIds', initialValue: [], label: 'Group Select' },
];
