import React from 'react';
import IconSmile from 'cccisd-icons/smile';
import IconSad from 'cccisd-icons/wondering';
import IconNotification from 'cccisd-icons/notification';
import style from './style.css';

const OverallPercent = props => {
    const { overallFidelity } = props.data;

    const targetFidelity = props.settings.targetScore
        ? props.settings.targetScore
        : props.data.targetFidelity;

    const _renderTargetInfo = () => {
        if (!overallFidelity || !targetFidelity) {
            return <div>No data found</div>;
        }
        let icon = <IconSmile spaceRight />;
        let color = '#3673b6';
        let targetText;
        if (overallFidelity > targetFidelity) {
            targetText = 'Above Target';
        } else if (overallFidelity === targetFidelity) {
            targetText = 'At Target';
        } else {
            icon = <IconSad spaceRight />;
            color = '#d03916';
            targetText = 'Below Target';
        }
        return (
            <>
                <div className={style.bigScore} style={{ color }}>
                    {icon} {overallFidelity}
                </div>
                <div className={style.targetIndicator} style={{ color }}>
                    <IconNotification /> {targetText}
                </div>
            </>
        );
    };

    return <div className={style.wrapper}>{_renderTargetInfo()}</div>;
};

export default OverallPercent;
