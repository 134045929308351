import React from 'react';
import {
    Field,
    FieldArray,
    CccisdToggle,
    CccisdInput,
    CccisdFieldWrapper,
    CccisdSelect,
} from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';
import Tooltip from 'cccisd-tooltip';
import { generateNewId } from '../../../../generateNewId.js';

export const initialValues = {
    show: true,
    title: 'My Table',
    description: '',
    columns: [
        {
            id: 'column0',
            label: 'Client ID',
            description: '',
            tooltip: true,
            render: 'abs',
            visualize: true,
        },
        {
            id: 'column1',
            label: 'Number sessions attended of total sessions',
            description: '',
            tooltip: true,
            render: 'xy',
            visualize: true,
        },
    ],
};

const columnRenderOptions = [
    { value: '', label: '-- Choose renderer --' },
    { value: 'xy', label: 'X/Y' },
    { value: 'abs', label: 'Absolute' },
    { value: 'percent', label: 'Percent' },
];

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Table Builder?" />
            <Field name="title" component={CccisdInput} label="Title" />
            <Field name="description" component={CccisdInput} label="Description" />
            <FieldArray
                name="columns"
                render={({ form, remove, push, name }) => {
                    // console.log(form);
                    return (
                        <CccisdFieldWrapper field={{ name }} form={form} label="Table Columns">
                            <div>
                                {form.values.columns.map((column, index) => (
                                    <div key={index} style={{ display: 'flex' }}>
                                        <div className="panel panel-default">
                                            <div className="panel-heading">
                                                <h3 className="panel-title">{`Column ${index +
                                                    1}`}</h3>
                                            </div>
                                            <div className="panel-body">
                                                <Field
                                                    name={`columns[${index}].label`}
                                                    component={CccisdInput}
                                                    label="Label"
                                                />
                                                <Field
                                                    name={`columns[${index}].description`}
                                                    component={CccisdInput}
                                                    label="Description"
                                                />
                                                <Field
                                                    name={`columns.${index}.tooltip`}
                                                    component={CccisdToggle}
                                                    label="Use description for column tooltip?"
                                                />
                                                <Field
                                                    name={`columns.${index}.render`}
                                                    component={CccisdSelect}
                                                    label="Renderer"
                                                    options={columnRenderOptions}
                                                />
                                                <Field
                                                    name={`columns.${index}.visualize`}
                                                    component={CccisdToggle}
                                                    label="Visualize?"
                                                />
                                            </div>
                                        </div>
                                        <Tooltip title={`Remove column ${index + 1}`}>
                                            <button
                                                type="button"
                                                className="btn btn-xs btn-danger"
                                                onClick={() => remove(index)}
                                            >
                                                -
                                            </button>
                                        </Tooltip>
                                    </div>
                                ))}
                                <button
                                    type="button"
                                    onClick={() =>
                                        push({
                                            id: generateNewId({
                                                prefix: 'column',
                                                settings: form.values.columns,
                                            }),
                                            label: '',
                                            visualize: false,
                                        })
                                    }
                                >
                                    + Add Column
                                </button>
                            </div>
                        </CccisdFieldWrapper>
                    );
                }}
            />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
