export default {
    query: async ({ query }) => {
        await new Promise(resolve => setTimeout(resolve, 100));
        return {
            data: {
                userList: [],
                totalCount: 5,
                filteredCount: 5,
            },
        };
    },
};
