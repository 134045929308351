import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'fidelityAdherenceTable',
    label: 'Fidelity Adherence Table',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
