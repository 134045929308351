import React from 'react';
import { Route } from 'cccisd-laravel-boilerplate';
import defaultProps from 'recompose/defaultProps.js';
import { Switch } from 'react-router-dom';
import AppDefs, { Dashboard as AppdefDashboard, Manage as AppdefManage, AdminRoutes } from 'cccisd-laravel-appdefs';
import { Quest, ViewData } from 'cccisd-laravel-assignment';

// Layouts
import FixedLayout from './layouts/FixedLayout';
import FluidLayout from './layouts/FluidLayout';
import NewLayout from './layouts/NewLayout';

// Pages
/* eslint-disable import/no-extraneous-dependencies, import/no-unresolved */
import Welcome from './pages/Welcome'; // Include it into main bundle

// Laravel packages
import Nexus from 'bundle-loader?lazy!cccisd-laravel-nexus';

// Impact Components
import SessionData from 'bundle-loader?lazy!./components/SessionData';
import OutcomesData from 'bundle-loader?lazy!./components/OutcomesData';
import DeploymentEvaluator from 'bundle-loader?lazy!./pages/DeploymentEvaluator';
import { AuthRedirect } from 'cccisd-laravel-nexus';
import { ResourceCenter } from 'cccisd-laravel-resources';
import ReportCenter from 'cccisd-report-center';

var AppDefinitions = window.cccisd.appDefs;
var Fortress = window.cccisd.fortress;

// Bind FixedLayout by default
const AppRoute = defaultProps({ layout: FixedLayout })(Route);

const appDefsComponentMatch = {
    SessionData,
    OutcomesData,
    AppdefManage,
    AppdefDashboard,
    ResourceCenter,
    ReportCenter,
    ViewData,
};

const appDefsLayoutMatch = {
    NewLayout,
    FluidLayout,
    FixedLayout,
};

export default () => (
    <Switch>
        <AppRoute path="/" component={Welcome} exact />
        {AppDefinitions.routes.map(route => {
            const Component = appDefsComponentMatch[route.componentHandle];
            if (!Component) {
                return null;
            }

            return (
                <AppRoute
                    key={route.handle}
                    path={route.url}
                    component={Component}
                    componentProps={route.componentProps}
                    exact
                    layout={'layout' in route ? appDefsLayoutMatch[route.layout] : FixedLayout}
                />
            );
        })}

        <AppRoute path="/" component={Welcome} exact />

        <AppRoute path="/reportCenter" component={ReportCenter} exact />

        <AppRoute
            exact
            path="/d/:deploymentHash/:respondentHash"
            component={DeploymentEvaluator}
            componentProps={{
                errorLayout: FixedLayout,
            }}
            layout={FixedLayout}
        />

        <AppRoute path={['/quest/preview', '/d', '/survey']} component={Quest} layout={null} />

        {/* Laravel packages routes */}
        {AdminRoutes(FixedLayout)}
        {Fortress.hasAccess('appdefs.admin') && <AppRoute path="/appdefs" component={AppDefs} layout={FluidLayout} />}

        {Fortress.auth() && (Fortress.user.acting.role.handle === 'orgAdmin' || Fortress.isSuperUser()) && (
            <AppRoute path="/viewData" component={ViewData} />
        )}

        <AppRoute path="/account" component={Nexus} />
        <AppRoute path={Fortress.settings.config.after_login_url} component={AuthRedirect} exact />

        {/* Not found page */}
        <AppRoute component={AuthRedirect} componentProps={{ isComponentOnly: true }} />
    </Switch>
);
