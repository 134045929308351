import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import calloutsQuery from './calloutsQuery.graphql';
import Callout from './components/Callout';
import _round from 'lodash/round';
import style from './style.css';

const Component = props => {
    const { show } = props.settings;
    const { isPreview, query } = props;

    const [data, loading] = useQuery({
        query: isPreview ? calloutsQuery : query,
        variables: props.filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const calloutsData = props.convertDataStructure(data);

    const {
        overallFidelity,
        targetFidelity,
        foundational,
        targetFoundational,
        leadership,
        targetLeadership,
    } = calloutsData;

    return (
        <>
            {show && (
                <div className={style.callouts}>
                    {props.settings.overallFidelity.show && (
                        <Callout
                            handle="overallValue"
                            settings={{
                                ...props.settings.overallFidelity,
                                colors: props.settings.colors,
                            }}
                            data={{ overallFidelity, targetFidelity }}
                        />
                    )}
                    {props.settings.foundational.show && (
                        <Callout
                            handle="foundational"
                            settings={{
                                ...props.settings.foundational,
                                colors: props.settings.colors,
                            }}
                            data={{
                                overallFidelity: foundational,
                                targetFidelity: targetFoundational,
                            }}
                        />
                    )}
                    {props.settings.leadership.show && (
                        <Callout
                            handle="leadership"
                            settings={{
                                ...props.settings.leadership,
                                colors: props.settings.colors,
                            }}
                            data={{ overallFidelity: leadership, targetFidelity: targetLeadership }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        let convertedData = {};

        if (data && data.groups.treatmentGroupList.length > 0) {
            const groupData =
                data.groups.treatmentGroupList[0].childRoles.metricspawn.assignmentProgressSummary;

            convertedData = {
                overallFidelity: _round(groupData.facilitatorFidelityScoreAvg, 1),
                targetFidelity: 4,
                foundational: _round(groupData.FidelityFoundationlElementsScoreAvg, 1),
                targetFoundational: 4,
                leadership: _round(groupData.FidelityLeadershipScoreAvg, 1),
                targetLeadership: 4,
            };
        } else {
            convertedData = {
                overallFidelity: 4,
                targetFidelity: 4,
                foundational: 3,
                targetFoundational: 4,
                leadership: 4,
                targetLeadership: 4,
            };
        }
        // console.log({ convertedData });
        return convertedData;
    },
};

export default Component;
