import React from 'react';
import { LoginForm } from 'cccisd-laravel-nexus';
var Fortress = window.cccisd.fortress;
// import { Link } from 'react-router-dom';
// var Boilerplate = window.cccisd.boilerplate;
// import _find from 'lodash/find';
var AppDefs = window.cccisd.appDefs;
// import Style from './style.css';
import { Html } from 'cccisd-wysiwyg';

export default class Component extends React.Component {
    render() {
        // let homepageRoute = _find(AppDefs.routes, r => r.handle === AppDefs.navs[0].homepage);
        return (
            <div className="landingPage" style={{ marginTop: '20px' }}>
                <div className="welcomeLogin">
                    <div className="row">
                        <div className="col-md-8">
                            <div>
                                <h1>
                                    <b>
                                        Welcome to <i>Impact</i> for Strong African American
                                        Families (SAAF)
                                    </b>
                                </h1>
                            </div>
                            <p>
                                <Html content={AppDefs.app.siteContent.landingPage} />
                            </p>
                        </div>
                        <div className="col-md-4">{!Fortress.auth() && <LoginForm />}</div>
                    </div>
                </div>
            </div>
        );
    }
}
