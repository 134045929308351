import React from 'react';
import style from './style.css';

const ShowValue = props => {
    const { colors } = props;
    return (
        <div className={style.wrapper} style={colors ? { color: colors.primary } : {}}>
            {props.data}
        </div>
    );
};

export default ShowValue;
