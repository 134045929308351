import React from 'react';
import { Field, CccisdInput, CccisdToggle, CccisdWysiwyg } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    title: 'Measurement Information',
    description:
        '<div>Define how overall fidelity is calculated, how lowest and highest sessions are determined, and how results are interpreted. Data displayed in this report are drawn from the following groups only.</div>',
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Info Tab?" />
            <Field name="title" component={CccisdInput} label="Title" />
            <Field name="description" component={CccisdWysiwyg} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
