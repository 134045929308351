import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    teenPre: {
        show: true,
        title: 'Youth Pre-Assessment',
        description: '',
    },
    teenPost: {
        show: true,
        title: 'Youth Post-Assessment',
        description: '',
    },
    caregiverPre: {
        show: true,
        title: 'Caregiver Pre-Assessment',
        description: '',
    },
    caregiverPost: {
        show: true,
        title: 'Caregiver Post-Assessment',
        description: '',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Callouts?" />
            <h4>Teen Pre-Assessment</h4>
            <Field
                name="teenPre.show"
                component={CccisdToggle}
                label="Show Youth Pre-Assessment?"
            />
            <Field name="teenPre.title" component={CccisdInput} label="Title" />
            <Field name="teenPre.description" component={CccisdInput} label="Description" />
            <h4>Teen Post-Assessment</h4>
            <Field
                name="teenPost.show"
                component={CccisdToggle}
                label="Show Youth Post-Assessment?"
            />
            <Field name="teenPost.title" component={CccisdInput} label="Title" />
            <Field name="teenPost.description" component={CccisdInput} label="Description" />
            <h4>Caregiver Pre-Assessment</h4>
            <Field
                name="caregiverPre.show"
                component={CccisdToggle}
                label="Show Caregiver Pre-Assessment?"
            />
            <Field name="caregiverPre.title" component={CccisdInput} label="Title" />
            <Field name="caregiverPre.description" component={CccisdInput} label="Description" />
            <h4>Caregiver Post-Assessment</h4>
            <Field
                name="caregiverPost.show"
                component={CccisdToggle}
                label="Show Caregiver Post-Assessment?"
            />
            <Field name="caregiverPost.title" component={CccisdInput} label="Title" />
            <Field name="caregiverPost.description" component={CccisdInput} label="Description" />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
