import React from 'react';
import PropTypes from 'prop-types';
import SummaryTable from './SummaryTable';
import outcomesQuery from './outcomesQuery.graphql';
import style from './style.css';

const LogTable = props => {
    const totalSessions = 5;

    const { title, description, show } = props.settings;
    // console.log(props.filters);
    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{title}</span>
                    </div>
                    <div style={{ marginBottom: '0.5em' }}>{description}</div>
                    <SummaryTable
                        totalSessions={totalSessions}
                        query={props.query ? props.query : outcomesQuery}
                        filters={props.filters}
                    />
                </div>
            )}
        </>
    );
};

LogTable.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

LogTable.defaultProps = {
    filters: {},
};

export default LogTable;
