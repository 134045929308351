import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import FidelityChart from './Chart';
import summaryChartQuery from './summaryChartQuery.graphql';
import _round from 'lodash/round';
import _mean from 'lodash/mean';
import style from './style.css';

const Component = props => {
    const [hidden, setHidden] = useState(true);

    const { title, description, show, chart } = props.settings;

    const [data, loading] = useQuery({
        query: props.query ? props.query : summaryChartQuery,
        variables: props.filters,
    });

    // Nivo charts sometimes dont render. Not sure what is going on. This function prevents chart render before parent element can render which seems to fix the problem.
    // Additional notes: Charts need parent element to have an explicit height to render. The chart is creating a parent div which uses a percent height. If this div is modified in the DOM after render, the chart will draw.
    // More notes: This is only happening in Report Center GUI. Does not happen in Reports GUI.
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const chartData = props.convertDataStructure(data);

    return (
        <>
            {show && (
                <div className={style.overallTable}>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{title}</span>
                    </div>
                    <div>{description}</div>
                    {hidden ? <div /> : <FidelityChart data={chartData} settings={chart} />}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        // console.log(data);
        let convertedData = [];

        if (data && data.roles.participantList.length > 0) {
            const getTeenScores = () => {
                let teenPreScores = [];
                let teenPostScores = [];
                data.roles.participantList.forEach(participant => {
                    participant.teenList.forEach(teen => {
                        const teenPre = teen.devTags.YouthPreScore;
                        const teenPost = teen.devTags.YouthPostScore;
                        if (teenPre !== null) {
                            teenPreScores.push(Number(teenPre));
                        }
                        if (teenPost !== null) {
                            teenPostScores.push(Number(teenPost));
                        }
                    });
                });

                const preScore = _round(_mean(teenPreScores), 1);
                const postScore = _round(_mean(teenPostScores), 1);
                return {
                    pre: Number.isNaN(preScore) ? null : preScore,
                    post: Number.isNaN(postScore) ? null : postScore,
                };
            };

            const getCaregiverScores = () => {
                let caregiverPreScores = [];
                let caregiverPostScores = [];
                data.roles.participantList.forEach(participant => {
                    participant.caregiverList.forEach(caregiver => {
                        const caregiverPre = caregiver.devTags.CaregiverPreScore;
                        const caregiverPost = caregiver.devTags.CaregiverPostScore;
                        if (caregiverPre !== null) {
                            caregiverPreScores.push(Number(caregiverPre));
                        }
                        if (caregiverPost !== null) {
                            caregiverPostScores.push(Number(caregiverPost));
                        }
                    });
                });
                const preScore = _round(_mean(caregiverPreScores), 1);
                const postScore = _round(_mean(caregiverPostScores), 1);
                return {
                    pre: Number.isNaN(preScore) ? null : preScore,
                    post: Number.isNaN(postScore) ? null : postScore,
                };
            };

            convertedData = [
                {
                    id: 'Youth Summary',
                    color: '#3673b6',
                    data: [
                        {
                            x: '',
                            y: null,
                        },
                        {
                            x: 'Pre',
                            y: getTeenScores().pre,
                        },
                        {
                            x: 'Post',
                            y: getTeenScores().post,
                        },
                        {
                            x: ' ',
                            y: null,
                        },
                    ],
                },
                {
                    id: 'Caregiver Summary',
                    color: '#65a84b',
                    data: [
                        {
                            x: '',
                            y: null,
                        },
                        {
                            x: 'Pre',
                            y: getCaregiverScores().pre,
                        },
                        {
                            x: 'Post',
                            y: getCaregiverScores().post,
                        },
                        {
                            x: ' ',
                            y: null,
                        },
                    ],
                },
            ];
        } else {
            convertedData = [
                {
                    id: 'Youth Summary',
                    color: '#3673b6',
                    data: [
                        {
                            x: '',
                            y: null,
                        },
                        {
                            x: 'Pre',
                            y: 64.25,
                        },
                        {
                            x: 'Post',
                            y: 73.75,
                        },
                        {
                            x: ' ',
                            y: null,
                        },
                    ],
                },
                {
                    id: 'Caregiver Summary',
                    color: '#65a84b',
                    data: [
                        {
                            x: '',
                            y: null,
                        },
                        {
                            x: 'Pre',
                            y: 70,
                        },
                        {
                            x: 'Post',
                            y: 75,
                        },
                        {
                            x: ' ',
                            y: null,
                        },
                    ],
                },
            ];
        }
        // console.log({ convertedData });
        return convertedData;
    },
};

export default Component;
