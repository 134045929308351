import React from 'react';
import PropTypes from 'prop-types';
import { Html } from 'cccisd-wysiwyg';
import Info from './Info';
import IconFile from 'cccisd-icons/file-text3';

const Component = props => {
    const { title, show, content, colors } = props.settings;

    return (
        <>
            {show && (
                <div style={{ marginBottom: '2em' }}>
                    <Info
                        icon={<IconFile spaceRight />}
                        title={title}
                        content={<Html content={content} />}
                        colors={colors}
                    />
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
};

export default Component;
