import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery';
import adherenceQuery from './adherenceQuery.graphql';
import ComponentsChart from './components/ComponentsChartBar';
import _mean from 'lodash/mean';
import _round from 'lodash/round';
import style from './style.css';

const FidelityComponentsChart = props => {
    const [hidden, setHidden] = useState(true);
    const { title, description, show, chart, colors } = props.settings;
    const { isPreview, query } = props;

    const [data, loading] = useQuery({
        query: isPreview ? adherenceQuery : query,
        variables: props.filters,
    });

    // See overallFidelityChart for comments
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const chartData = props.convertDataStructure(data);

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{title}</span>
                    </div>
                    <div>{description}</div>
                    {hidden ? (
                        <div />
                    ) : (
                        <ComponentsChart data={chartData} settings={chart} colors={colors} />
                    )}
                </div>
            )}
        </>
    );
};

FidelityComponentsChart.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

FidelityComponentsChart.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        // console.log(data);
        let convertedData = [];

        if (data && data.groups.treatmentGroupList.length > 0) {
            const sessionScores = {
                teen: [[], [], [], [], [], [], []],
                caregiver: [[], [], [], [], [], [], []],
                family: [[], [], [], [], [], [], []],
            };
            data.groups.treatmentGroupList.forEach(tg => {
                const APL = tg.childRoles.metricspawn.assignmentProgressList;

                for (let i = 0; i < 7; i++) {
                    const session = APL.find(s => s.deployment.label === `Session ${i + 1}`);
                    if (session) {
                        const teenScore = session.devTags.YouthSessionFidelityScore;
                        const cgScore = session.devTags.CaregiverSessionFidelityScore;
                        const familyScore = session.devTags.FamilySessionFidelityScore;

                        if (teenScore !== null) {
                            sessionScores.teen[i].push(Number(teenScore));
                        }
                        if (cgScore !== null) {
                            sessionScores.caregiver[i].push(Number(cgScore));
                        }
                        if (familyScore !== null) {
                            sessionScores.family[i].push(Number(familyScore));
                        }
                    }
                }
            });
            // console.log(sessionScores);
            const getAdherenceData = group => {
                let title = group[0].toUpperCase() + group.slice(1);
                if (title === 'Teen') {
                    title = 'Youth';
                }
                let adherenceData = {
                    metric: `${title} Adherence`,
                };
                for (let i = 0; i <= 6; i++) {
                    adherenceData[`Session ${i + 1}`] = _round(_mean(sessionScores[group][i]));
                }
                // console.log(adherenceData);
                return adherenceData;
            };
            convertedData = [
                getAdherenceData('teen'),
                getAdherenceData('caregiver'),
                getAdherenceData('family'),
            ];
        } else {
            convertedData = [
                {
                    metric: 'Youth Adherence',
                    'Session 1': 70,
                    'Session 2': 34,
                    'Session 3': 85,
                    'Session 4': 94,
                    'Session 5': 80,
                    'Session 6': 85,
                    'Session 7': 76,
                },
                {
                    metric: 'Caregiver Adherence',
                    'Session 1': 53,
                    'Session 2': 55,
                    'Session 3': 70,
                    'Session 4': 56,
                    'Session 5': 71,
                    'Session 6': 85,
                    'Session 7': 76,
                },
                {
                    metric: 'Family Adherence',
                    'Session 1': 61,
                    'Session 2': 54,
                    'Session 3': 90,
                    'Session 4': 83,
                    'Session 5': 74,
                    'Session 6': 85,
                    'Session 7': 76,
                },
            ];
        }
        // console.log({ convertedData });
        return convertedData;
    },
};

export default FidelityComponentsChart;
