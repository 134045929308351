import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'outcomesSummaryChart',
    label: 'Outcomes Summary Chart',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
