import React from 'react';
import mockClient from '../_mockApolloClient.js';
import Table from 'cccisd-graphql-table';

const TableBuilder = props => {
    const getColumnRenderer = (value, renderer) => {
        // TODO
        switch (renderer) {
            case 'abs':
                return value;
            case 'percent':
                return <>{value}%</>;
            case 'xy':
                return (
                    <>
                        {value}/{value}
                    </>
                );
            default:
                return value;
        }
    };

    const columns = props.columns.map(column => ({
        name: column.id,
        label: column.label,
        render: ({ value }) => getColumnRenderer(value, column.render),
        tooltip: column.tooltip ? column.description : undefined,
        sort: true,
    }));

    return (
        <Table
            apolloClient={props.isPreview ? mockClient : undefined}
            query={props.query}
            columns={columns}
        />
    );
};

export default TableBuilder;
