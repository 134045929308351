import React from 'react';
import PropTypes from 'prop-types';
import { Field, CccisdInput, CccisdWysiwyg } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import ColorPicker from '../../ColorPicker';
import filterFields from '../filterFields.js';
import widgets from '../widgets.js';

export const initialValues = {
    filterFields: filterFields.map(item => item.name),
    otherFilterOptions: [],
    colors: { primary: '#3673b6' },
    header: {
        title: 'Outcomes Summary Report: Individual Level',
        subtitle: 'Tracks the degree to which the program is delivered as intended',
        groupLeaderText: 'Report For:',
        reportingPeriodText: 'Reporting Period:',
        createdDateText: 'Report Created On:',
        description:
            'The Outcomes Report compiles and summaries session outcomes and data collected on each participant in a group across all sessions in the program.',
        numGroupsText: '',
        numParticipantsText: '',
    },
    body: {
        heading:
            'Teens and Caregivers are surveyed before and after treatment services to assess teens’ confidence and competence to make good decisions about their future as well as caregivers’ ability to maintain effective communication with their teen. Scores range from low (0) to high (80) with higher scores indicating greater positive results for families including stronger communication within the family.',
    },
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = props => {
    return (
        <div>
            <BuilderBlock title="Header">
                <Field name="header.title" component={CccisdInput} label="Title" />
                <Field name="header.subtitle" component={CccisdInput} label="Subtitle" />
                <Field name="header.description" component={CccisdInput} label="Description" />
                <Field
                    name="header.reportingPeriodText"
                    component={CccisdInput}
                    label="Reporting Period Text"
                />
                <Field
                    name="header.createdDateText"
                    component={CccisdInput}
                    label="Created Date Text"
                />
                <BuilderBlock title="Subheader">
                    <Field
                        name="header.groupLeaderText"
                        component={CccisdInput}
                        label="Group Leader Text"
                    />
                    <Field
                        name="header.numGroupsText"
                        component={CccisdInput}
                        label="Number of Groups Text"
                    />
                    <Field
                        name="header.numParticipantsText"
                        component={CccisdInput}
                        label="Number of Individuals Text"
                    />
                </BuilderBlock>
            </BuilderBlock>
            <BuilderBlock title="Body">
                <Field name="body.heading" component={CccisdWysiwyg} label="Page Heading" />
            </BuilderBlock>
            <BuilderBlock title="Color">
                <Field name="colors.primary">
                    {({ field, form }) => {
                        return <ColorPicker field={field} form={form} />;
                    }}
                </Field>
            </BuilderBlock>
        </div>
    );
};

Component.propTypes = {
    onValidChange: PropTypes.func,
    settings: PropTypes.object,
};

export default reportTemplateBuilder({ filterFields, widgets })(Component);
