/* eslint-disable no-restricted-globals */
import React from 'react';
import _isNil from 'lodash/isNil';

/*
 * Displays the facilitators used for this group in all sessions
 *
 * A row per facilitator in alpha order
 */
export default settings => {
    return ({ value, row }) => {
        let facilitatorNames = [
            ...new Set(
                row.metricsList.map(response => response.devTags.FacilitatorEvaluationPawnName)
            ),
        ];

        return (
            <div>
                {facilitatorNames.map(name => {
                    if (!_isNil(name)) {
                        return <div key={name}>{name}</div>;
                    }

                    return '';
                })}
            </div>
        );
    };
};
