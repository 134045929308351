import React from 'react';
import Table from 'cccisd-graphql-table';
import mockClient from '../_mockApolloClient.js';

const SummaryTable = props => {
    const _renderValues = value => (
        <>
            {value}/{props.totalSessions}
        </>
    );

    const columns = [
        { name: 'name', label: 'Participant List', sort: true },
        {
            name: 'attended',
            label: 'Sessions Attended',
            sort: true,
            className: 'text-center',
            render: ({ value }) => _renderValues(value),
        },
        {
            name: 'participated',
            label: 'Participated',
            sort: true,
            className: 'text-center',
            render: ({ value }) => _renderValues(value),
        },
        {
            name: 'behaviorProblems',
            label: 'Behavior Problems',
            sort: true,
            className: 'text-center',
            render: ({ value }) => _renderValues(value),
        },
        {
            name: 'redirection',
            label: 'Needed Redirection',
            sort: true,
            className: 'text-center',
            render: ({ value }) => _renderValues(value),
        },
        {
            name: 'skills',
            label: 'Demonstrated Skills',
            sort: true,
            className: 'text-center',
            render: ({ value }) => _renderValues(value),
        },
    ];

    return (
        <Table
            apolloClient={props.isPreview ? mockClient : undefined}
            query={props.query}
            columns={columns}
        />
    );
};

export default SummaryTable;
