import fidelityComments from './widgets/fidelityComments';
import fidelitySGSummaryCallouts from './widgets/fidelitySGSummaryCallouts';
import fidelitySGOverallChart from './widgets/fidelitySGOverallChart';
import overallFidelityInfoTab from './widgets/overallFidelityInfoTab';
import fidelitySGAverageChart from './widgets/fidelitySGAverageChart';
import fidelitySGAdherenceChart from './widgets/fidelitySGAdherenceChart';
import componentsCallouts from './widgets/componentsCallouts';
import componentsInfoTab from './widgets/componentsInfoTab';
import fidelitySGFacilitatorChart from './widgets/fidelitySGFacilitatorChart';
import fidelitySGFacilitatorTable from './widgets/fidelitySGFacilitatorTable';
import fidelitySummaryTable from './widgets/fidelitySummaryTable';
import fidelityAdherenceTable from './widgets/fidelityAdherenceTable';
import summaryTableInfoTab from './widgets/summaryTableInfoTab';
import recommendations from './widgets/recommendations';

export default [
    fidelitySGSummaryCallouts,
    fidelitySGOverallChart,
    fidelitySGAverageChart,
    fidelitySummaryTable,
    overallFidelityInfoTab,
    fidelitySGFacilitatorChart,
    fidelitySGFacilitatorTable,
    summaryTableInfoTab,
    componentsCallouts,
    fidelitySGAdherenceChart,
    fidelityAdherenceTable,
    componentsInfoTab,
    fidelityComments,
    recommendations,
];
