import React from 'react';
import style from './style.css';
import _get from 'lodash/get';

export default settings => {
    return ({ value, row }) => {
        const list = _get(row, settings.path);
        const minAttendance = settings.minimum;
        let result;

        let threePlusCount = 0;
        let attendedCount = 0;

        list.forEach(item => {
            const metricsList = item.metricsList;
            let attendanceArr = [];

            metricsList.forEach(object => {
                if (object.devTags.YouthSessionAttendance === '1') {
                    attendanceArr.push(object.devTags.YouthSessionAttendance);
                }
            });

            // attended atleaset 3 sessions
            if (attendanceArr.length >= 3) {
                threePlusCount++;
            }

            // attended atleast 1 session
            if (attendanceArr.length < 3 && attendanceArr.length > 0) {
                attendedCount++;
            }
        });

        if (minAttendance === 3) {
            result = threePlusCount;
        } else if (minAttendance === 1) {
            result = attendedCount;
        }

        return <div className={style.cell}>{result}</div>;
    };
};
