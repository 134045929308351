import React from 'react';
import PropTypes from 'prop-types';
import { Html } from 'cccisd-wysiwyg';
import { Report, Page } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import widgets from '../widgets.js';
import filterFields from '../filterFields.js';
import ReportHeader from '../../ReportHeader';
import style from './style.css';

const Component = props => {
    // console.log(props.data);
    const {
        logSummaryCallouts,
        logTable,
        logAttendanceTracker,
        tableBuilder,
        logComments,
    } = props.widgets;

    const {
        body: { heading },
    } = props.settings;

    return (
        <Report>
            <Page>
                <div className={style.wrapper}>
                    <ReportHeader
                        settings={props.settings}
                        filters={props.filters}
                        isPreview={props.isPreview}
                        data={props.data}
                    />
                    <div className={style.reportBodyText}>
                        <Html content={heading} />
                    </div>
                    {logSummaryCallouts}
                    {logTable}
                    {logAttendanceTracker}
                    {tableBuilder}
                    {logComments}
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

export default reportTemplatePlayer({
    widgets,
    getFilterFields: props =>
        filterFields.map(field =>
            field.name === 'groupIds'
                ? {
                      ...field,
                      initialValue: props.data.groups.treatmentGroupList
                          .map(tg => tg.group.groupId)
                          .slice(0, 1),
                  }
                : field
        ),
})(Component);
