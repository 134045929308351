import { useState, useEffect, useRef } from 'react';
import { client, gql } from 'cccisd-apollo';
import _isEqual from 'lodash/isEqual';

export default ({ query, variables, fakeData }) => {
    const [data, setData] = useState(fakeData || []);
    const [loading, setLoading] = useState(true);

    const isFirst = useRef(true);
    const prevDeps = useRef(variables);

    useEffect(() => {
        const isSame = _isEqual(variables, prevDeps);

        if (isFirst.current || !isSame) {
            (async () => {
                setLoading(true);
                if (!fakeData) {
                    const formattedQuery = gql`
                        ${query}
                    `;
                    const response = await client.query({ query: formattedQuery, variables });
                    setData(response.data);
                }
                setLoading(false);
            })();
        }
        isFirst.current = false;
        prevDeps.current = variables;
    }, [variables]);

    return [data, loading];
};
