import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import Table from 'cccisd-graphql-table';
import { BrowserOnly } from 'cccisd-laravel-report';
import IconComment from 'cccisd-icons/bubble-dots3';
import IconPlus from 'cccisd-icons/plus2';
import IconMinus from 'cccisd-icons/minus2';
import IconMenu from 'cccisd-icons/menu7';
import IconSpinner from 'cccisd-icons/spinner8';
import style from './style.css';

const Comment = props => {
    return (
        <div className={style.commentWrapper}>
            {props.data.some(d => d.teenComment || d.caregiverComment) ? (
                <>
                    <div className={style.info}>One thing I learned from SAAF is...</div>
                    {props.data.map(p => {
                        if (p.teenComment || p.caregiverComment) {
                            return (
                                <div className={style.commentBox} key={p.id}>
                                    <span style={{ fontWeight: 'bold', fontSize: '1.1em' }}>
                                        {p.participantName}
                                    </span>
                                    {p.teenComment && (
                                        <div className={style.commentBlock}>
                                            <div className={style.commentHeading}>
                                                Youth Comment
                                            </div>
                                            <div className={style.comment}>{p.teenComment}</div>
                                        </div>
                                    )}
                                    {p.caregiverComment && (
                                        <div className={style.commentBlock}>
                                            <div className={style.commentHeading}>
                                                Caregiver Comment
                                            </div>
                                            <div className={style.comment}>
                                                {p.caregiverComment}
                                            </div>
                                        </div>
                                    )}
                                </div>
                            );
                        }
                        return null;
                    })}
                </>
            ) : (
                <div>No comments have been entered for this group.</div>
            )}
        </div>
    );
};

const Component = props => {
    const [boxOpen, setBoxOpen] = useState(true);

    const { title } = props.settings;
    const { colors } = props;

    const _renderCommentBox = data => {
        return <Comment data={data} />;
    };

    const renderComments = tableProps => {
        if (!tableProps.loading) {
            return (
                <div>
                    <BrowserOnly>
                        <div className="btn-group" style={{ marginBottom: '0.7em' }}>
                            <button
                                type="button"
                                className={`btn btn-sm btn-default dropdown-toggle ${
                                    style.multipurposeButton
                                } ${tableProps.loading ? style.rotating : ''}`}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {tableProps.loading ? <IconSpinner /> : <IconMenu />}
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a href="" onClick={tableProps.loadData}>
                                        Reload data
                                    </a>
                                </li>
                                {/* <li>
                                <a href="" onClick={tableProps.downloadCsv}>
                                    Download CSV
                                </a>
                            </li> */}
                            </ul>
                        </div>
                    </BrowserOnly>
                    <div
                        className={style.wrapper}
                        style={colors ? { borderColor: colors.primary } : {}}
                    >
                        <div
                            className={style.header}
                            style={colors ? { backgroundColor: colors.primary } : {}}
                        >
                            <div>
                                <IconComment spaceRight />
                                {title}
                            </div>
                            <button
                                type="button"
                                className={`btn btn-sm ${style.menuButton}`}
                                onClick={() => setBoxOpen(!boxOpen)}
                                style={
                                    colors
                                        ? { backgroundColor: Color(colors.primary).darken(0.2) }
                                        : {}
                                }
                            >
                                {boxOpen ? <IconMinus /> : <IconPlus />}
                            </button>
                        </div>
                        {boxOpen
                            ? _renderCommentBox(props.convertDataStructure(tableProps.data))
                            : null}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <Table
            graphqlVariables={{
                groupIds: props.filters.groupIds,
                startDate: props.filters.startDate,
                endDate: props.filters.endDate,
            }}
            query={props.query}
            columns={[]}
            render={renderComments}
        />
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    data: PropTypes.array,
    isPreview: PropTypes.bool,
    query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    colors: PropTypes.object,
    convertDataStructure: PropTypes.func,
    filters: PropTypes.object,
};

Component.defaultProps = {
    convertDataStructure: data => {
        // console.log(data);
        let convertedData = [];
        if (data.length > 0) {
            convertedData = data.map(participant => {
                const teenCommentObject = participant.teenList.find(
                    i => i.deployment.label === 'Post Treatment'
                );
                const caregiverCommentObject = participant.caregiverList.find(
                    i => i.deployment.label === 'Post Treatment'
                );
                return {
                    id: participant['pawn.pawnId'],
                    participantName: participant['fields.participantId'],
                    teenComment: teenCommentObject
                        ? teenCommentObject.devTags.YouthPostComment
                        : '',
                    caregiverComment: caregiverCommentObject
                        ? caregiverCommentObject.devTags.CaregiverPostComment
                        : '',
                };
            });
        } else {
            convertedData = [
                {
                    id: 1,
                    participantName: 'Youth One',
                    teenComment: 'I learned a lot.',
                    caregiverComment: 'Talking to my youth about racism is important.',
                },
                {
                    id: 2,
                    participantName: 'Youth Two',
                    teenComment: 'Youth Two Youth Comment.',
                    caregiverComment: 'Youth Two Caregiver Comment',
                },
            ];
        }
        return convertedData;
    },
};

export default Component;
