import React from 'react';
import { ResponsiveLine } from 'cccisd-nivo/line';

const MyResponsiveLine = props => {
    const {
        targetLineValue,
        verticalScaleMin,
        verticalScaleMax,
        scoreSuffix,
        xLabel,
        yLabel,
        showLegend,
    } = props.settings;

    return (
        <div style={{ position: 'relative', height: '300px' }}>
            <ResponsiveLine
                data={props.data}
                enableSlices="x"
                yScale={{
                    type: 'linear',
                    min: Number(verticalScaleMin),
                    max: Number(verticalScaleMax),
                }}
                axisBottom={{
                    orient: 'bottom',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: xLabel,
                    legendOffset: 36,
                    legendPosition: 'middle',
                }}
                axisLeft={{
                    orient: 'left',
                    tickSize: 5,
                    tickPadding: 5,
                    tickRotation: 0,
                    legend: yLabel,
                    legendOffset: -46,
                    legendPosition: 'middle',
                    format: n => `${n}${scoreSuffix}`,
                }}
                markers={
                    targetLineValue
                        ? [
                              {
                                  axis: 'y',
                                  value: Number(targetLineValue),
                                  lineStyle: {
                                      stroke: '#b1cbe7',
                                      strokeWidth: 2,
                                      strokeDasharray: '10,5',
                                  },
                                  legend: '',
                                  legendOrientation: 'horizontal',
                              },
                          ]
                        : []
                }
                colors={{ scheme: 'category10' }}
                enableGridX
                layers={
                    showLegend
                        ? [
                              'grid',
                              'markers',
                              'axes',
                              'lines',
                              'points',
                              'slices',
                              'mesh',
                              'legends',
                          ]
                        : ['grid', 'markers', 'axes', 'lines', 'points', 'slices', 'mesh']
                }
                sliceTooltip={({ slice }) => {
                    return (
                        <div
                            style={{
                                background: 'white',
                                padding: '9px 12px',
                                border: '1px solid #ccc',
                            }}
                        >
                            {slice.points.map(point => (
                                <div key={point.id}>
                                    <span
                                        style={{
                                            color: point.serieColor,
                                            padding: '3px 0',
                                        }}
                                    >
                                        {point.serieId}:{' '}
                                    </span>
                                    <strong>
                                        {point.data.yFormatted}
                                        {scoreSuffix}
                                    </strong>
                                </div>
                            ))}
                        </div>
                    );
                }}
            />
        </div>
    );
};

export default MyResponsiveLine;
