import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import calloutsQuery from './calloutsQuery.graphql';
import Callout from './Callout';
import _round from 'lodash/round';
import style from './style.css';

const Component = props => {
    const { show } = props.settings;
    const { isPreview, query } = props;

    const [data, loading] = useQuery({
        query: isPreview ? calloutsQuery : query,
        variables: props.filters,
    });
    // if (!loading) {
    //     console.log(data);
    // }

    if (loading) {
        return <Loader loading />;
    }

    const calloutsData = props.convertDataStructure(data);

    const {
        teenAttendance,
        targetAttendance,
        teensAttendedPct,
        targetTeensPct,
        caregiverAttendance,
        targetCaregiverAttendance,
    } = calloutsData;

    // console.log({ calloutsData });

    return (
        <>
            {show && (
                <div className={style.callouts}>
                    {props.settings.teenAttendance.show && (
                        <Callout
                            handle="teenAttendance"
                            settings={{
                                ...props.settings.teenAttendance,
                                colors: props.settings.colors,
                            }}
                            data={{ value: teenAttendance, target: targetAttendance }}
                        />
                    )}
                    {props.settings.teensAttended.show && (
                        <Callout
                            handle="teensAttended"
                            settings={{
                                ...props.settings.teensAttended,
                                colors: props.settings.colors,
                            }}
                            data={{ value: teensAttendedPct, target: targetTeensPct }}
                        />
                    )}
                    {props.settings.caregiverAttendance.show && (
                        <Callout
                            handle="caregiverAttendance"
                            settings={{
                                ...props.settings.caregiverAttendance,
                                colors: props.settings.colors,
                            }}
                            data={{ value: caregiverAttendance, target: targetCaregiverAttendance }}
                        />
                    )}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        let convertedData = {};
        if (data && data.groups.treatmentGroupList.length > 0) {
            const getAttendanceRate = node => {
                let attendanceAllGroups = [];
                data.groups.treatmentGroupList.forEach(tg => {
                    let val;
                    tg.childRoles.participantList.forEach(pl => {
                        val = pl.assignmentProgressSummary[node[0]];
                        if (typeof val !== 'object') {
                            attendanceAllGroups.push(val * 100);
                        }
                    });
                });

                let averageAttendance;
                // console.log(attendanceAllGroups);
                if (attendanceAllGroups.length > 0) {
                    if (attendanceAllGroups.length > 1) {
                        averageAttendance =
                            attendanceAllGroups.reduce((a, b) => a + b) /
                            attendanceAllGroups.length;
                    } else {
                        return _round(attendanceAllGroups[0]);
                    }
                } else {
                    return '';
                }
                return _round(averageAttendance);
            };

            const getTeensAttendedPct = () => {
                let numTeensAttendedThreePlusSessions = 0;
                let totalTeens = 0;

                data.groups.treatmentGroupList.forEach(tg => {
                    tg.childRoles.participantList.forEach(participant => {
                        const didAttend = participant.assignmentProgressSummary.frequency.find(
                            f => f.value === '1'
                        );
                        const didNotAttend = participant.assignmentProgressSummary.frequency.find(
                            f => f.value === '0'
                        );
                        if (didAttend && didNotAttend) {
                            if (didAttend.valueCount >= 3 || didNotAttend.valueCount >= 3) {
                                if (didAttend.valueCount >= 3) {
                                    numTeensAttendedThreePlusSessions++;
                                }
                                totalTeens++;
                            } else if (didAttend.valueCount + didNotAttend.valueCount >= 3) {
                                if (didAttend.valueCount >= 3) {
                                    numTeensAttendedThreePlusSessions++;
                                }
                                totalTeens++;
                            }
                        } else if (didAttend) {
                            if (didAttend.valueCount >= 3) {
                                numTeensAttendedThreePlusSessions++;
                            }
                            totalTeens++;
                        } else if (didNotAttend) {
                            if (didNotAttend.valueCount >= 3) {
                                totalTeens++;
                            }
                        } else {
                            totalTeens++;
                        }
                    });
                });

                // console.log({ numTeensAttendedThreePlusSessions, totalTeens });
                return _round((numTeensAttendedThreePlusSessions / totalTeens) * 100);
            };

            convertedData = {
                teenAttendance: getAttendanceRate(['youthAttAvg']),
                targetAttendance: 80,
                teensAttendedPct: getTeensAttendedPct(),
                targetTeensPct: 80,
                caregiverAttendance: getAttendanceRate(['caregiverAttAvg']),
                targetCaregiverAttendance: 80,
            };
        } else {
            convertedData = {
                teenAttendance: 60,
                targetAttendance: 80,
                teensAttendedPct: 70,
                targetTeensPct: 80,
                caregiverAttendance: 85,
                targetCaregiverAttendance: 80,
            };
        }
        // console.log({ convertedData });
        return convertedData;
    },
};

export default Component;
