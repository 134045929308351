import FidelitySingleGroupReportTemplate from './FidelitySingleGroup';
import OutcomesGroupReportTemplate from './OutcomesGroup';
import OutcomesGroupComparisonReportTemplate from './OutcomesGroupComparison';
import OutcomesIndividualReportTemplate from './OutcomesIndividual';
import LogReportTemplate from './Log';
import _round from 'lodash/round';
import _mean from 'lodash/mean';

// Graphql
import outcomesGCSummaryCalloutsQuery from './graphql/outcomesGCSummaryCallouts.graphql';
import outcomesGCSummaryChartQuery from './graphql/outcomesGCSummaryChart.graphql';
import outcomesGCCommentsQuery from './graphql/outcomesGCComments.graphql';
import outcomesParticipantChartsQuery from './graphql/outcomesParticipantCharts.graphql';
import outcomesIndividualSummaryCalloutsQuery from './graphql/outcomesIndividualSummaryCallouts.graphql';
import outcomesIndividualSummaryChartQuery from './graphql/outcomesIndividualSummaryChart.graphql';
import outcomesIndividualSummaryTableQuery from './graphql/outcomesIndividualSummaryTable.graphql';
import outcomesIndividualCommentsQuery from './graphql/outcomesIndividualComments.graphql';
import logCommentsQuery from './graphql/logComments.graphql';
import fidelityCommentsQuery from './graphql/fidelityComments.graphql';

export default [
    {
        ...FidelitySingleGroupReportTemplate,
        widgetProps: {
            fidelityComments: {
                query: fidelityCommentsQuery,
                convertDataStructure: data => {
                    // console.log(data);
                    let convertedData = [];
                    if (data.length > 0) {
                        convertedData = data.map(group => {
                            return {
                                id: group['group.groupId'],
                                groupName: group['group.label'],
                                sessionData: [
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 1,
                                        sessionDate: group['childRoles.metricspawn.session1.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session1.devTags.YouthSessionFidelityComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session1.devTags.CaregiverSessionFidelityComments'
                                            ],
                                        familyComment:
                                            group[
                                                'childRoles.metricspawn.session1.devTags.FamilySessionFidelityComments'
                                            ],
                                        checklistFacilitator1Comment:
                                            group[
                                                'childRoles.metricspawn.session1.devTags.FidelityChecklistFacilitator1Comments'
                                            ],
                                        checklistFacilitator2Comment:
                                            group[
                                                'childRoles.metricspawn.session1.devTags.FidelityChecklistFacilitator2Comments'
                                            ],
                                        checklistFacilitator3Comment:
                                            group[
                                                'childRoles.metricspawn.session1.devTags.FidelityChecklistFacilitator3Comments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 2,
                                        sessionDate: group['childRoles.metricspawn.session2.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session2.devTags.YouthSessionFidelityComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session2.devTags.CaregiverSessionFidelityComments'
                                            ],
                                        familyComment:
                                            group[
                                                'childRoles.metricspawn.session2.devTags.FamilySessionFidelityComments'
                                            ],
                                        checklistFacilitator1Comment:
                                            group[
                                                'childRoles.metricspawn.session2.devTags.FidelityChecklistFacilitator1Comments'
                                            ],
                                        checklistFacilitator2Comment:
                                            group[
                                                'childRoles.metricspawn.session2.devTags.FidelityChecklistFacilitator2Comments'
                                            ],
                                        checklistFacilitator3Comment:
                                            group[
                                                'childRoles.metricspawn.session2.devTags.FidelityChecklistFacilitator3Comments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 3,
                                        sessionDate: group['childRoles.metricspawn.session3.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session3.devTags.YouthSessionFidelityComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session3.devTags.CaregiverSessionFidelityComments'
                                            ],
                                        familyComment:
                                            group[
                                                'childRoles.metricspawn.session3.devTags.FamilySessionFidelityComments'
                                            ],
                                        checklistFacilitator1Comment:
                                            group[
                                                'childRoles.metricspawn.session3.devTags.FidelityChecklistFacilitator1Comments'
                                            ],
                                        checklistFacilitator2Comment:
                                            group[
                                                'childRoles.metricspawn.session3.devTags.FidelityChecklistFacilitator2Comments'
                                            ],
                                        checklistFacilitator3Comment:
                                            group[
                                                'childRoles.metricspawn.session3.devTags.FidelityChecklistFacilitator3Comments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 4,
                                        sessionDate: group['childRoles.metricspawn.session4.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session4.devTags.YouthSessionFidelityComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session4.devTags.CaregiverSessionFidelityComments'
                                            ],
                                        familyComment:
                                            group[
                                                'childRoles.metricspawn.session4.devTags.FamilySessionFidelityComments'
                                            ],
                                        checklistFacilitator1Comment:
                                            group[
                                                'childRoles.metricspawn.session4.devTags.FidelityChecklistFacilitator1Comments'
                                            ],
                                        checklistFacilitator2Comment:
                                            group[
                                                'childRoles.metricspawn.session4.devTags.FidelityChecklistFacilitator2Comments'
                                            ],
                                        checklistFacilitator3Comment:
                                            group[
                                                'childRoles.metricspawn.session4.devTags.FidelityChecklistFacilitator3Comments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 5,
                                        sessionDate: group['childRoles.metricspawn.session5.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session5.devTags.YouthSessionFidelityComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session5.devTags.CaregiverSessionFidelityComments'
                                            ],
                                        familyComment:
                                            group[
                                                'childRoles.metricspawn.session5.devTags.FamilySessionFidelityComments'
                                            ],
                                        checklistFacilitator1Comment:
                                            group[
                                                'childRoles.metricspawn.session5.devTags.FidelityChecklistFacilitator1Comments'
                                            ],
                                        checklistFacilitator2Comment:
                                            group[
                                                'childRoles.metricspawn.session5.devTags.FidelityChecklistFacilitator2Comments'
                                            ],
                                        checklistFacilitator3Comment:
                                            group[
                                                'childRoles.metricspawn.session5.devTags.FidelityChecklistFacilitator3Comments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 6,
                                        sessionDate: group['childRoles.metricspawn.session6.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session6.devTags.YouthSessionFidelityComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session6.devTags.CaregiverSessionFidelityComments'
                                            ],
                                        familyComment:
                                            group[
                                                'childRoles.metricspawn.session6.devTags.FamilySessionFidelityComments'
                                            ],
                                        checklistFacilitator1Comment:
                                            group[
                                                'childRoles.metricspawn.session6.devTags.FidelityChecklistFacilitator1Comments'
                                            ],
                                        checklistFacilitator2Comment:
                                            group[
                                                'childRoles.metricspawn.session6.devTags.FidelityChecklistFacilitator2Comments'
                                            ],
                                        checklistFacilitator3Comment:
                                            group[
                                                'childRoles.metricspawn.session6.devTags.FidelityChecklistFacilitator3Comments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 7,
                                        sessionDate: group['childRoles.metricspawn.session7.substr'],
                                        teenComment:
                                            group[
                                                'childRoles.metricspawn.session7.devTags.YouthSessionFidelityComments'
                                            ],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session7.devTags.CaregiverSessionFidelityComments'
                                            ],
                                        familyComment:
                                            group[
                                                'childRoles.metricspawn.session7.devTags.FamilySessionFidelityComments'
                                            ],
                                        checklistFacilitator1Comment:
                                            group[
                                                'childRoles.metricspawn.session7.devTags.FidelityChecklistFacilitator1Comments'
                                            ],
                                        checklistFacilitator2Comment:
                                            group[
                                                'childRoles.metricspawn.session7.devTags.FidelityChecklistFacilitator2Comments'
                                            ],
                                        checklistFacilitator3Comment:
                                            group[
                                                'childRoles.metricspawn.session7.devTags.FidelityChecklistFacilitator3Comments'
                                            ],
                                    },
                                ],
                            };
                        });
                    } else {
                        convertedData = [
                            {
                                id: 10,
                                groupName: 'My Group',
                                sessionData: [
                                    {
                                        id: 10,
                                        groupName: 'My Group',
                                        session: 1,
                                        sessionDate: '2020-10-01',
                                        teenComment: 'Youth Comment Session 1',
                                        caregiverComment: 'Caregiver comment session 1',
                                        familyComment: 'Family adherence comment session 1',
                                        checklistFacilitator1Comment: 'Checklist Facilitator 1',
                                        checklistFacilitator2Comment: 'Checklist Facilitator 2',
                                        checklistFacilitator3Comment: 'Checklist Facilitator 3',
                                    },
                                    {
                                        id: 10,
                                        groupName: 'My Group',
                                        session: 2,
                                        sessionDate: '2020-10-08',
                                        teenComment: 'Youth comment session 2',
                                        caregiverComment: 'caregiver comment session 2',
                                        familyComment: 'Family adherence comment session 2',
                                        checklistFacilitator1Comment: 'Checklist Facilitator 1',
                                        checklistFacilitator2Comment: 'Checklist Facilitator 2',
                                        checklistFacilitator3Comment: 'Checklist Facilitator 3',
                                    },
                                ],
                            },
                        ];
                    }
                    return convertedData;
                },
            },
        },
    },
    {
        ...LogReportTemplate,
        widgetProps: {
            logSummaryCallouts: {
                convertDataStructure: data => {
                    let convertedData = {};
                    if (data && data.groups.treatmentGroupList.length > 0) {
                        const getAttendanceRate = node => {
                            let attendanceAllGroups = [];
                            data.groups.treatmentGroupList.forEach(tg => {
                                let val;
                                tg.childRoles.participantList.forEach(pl => {
                                    val = pl.assignmentProgressSummary[node[0]];
                                    if (typeof val !== 'object') {
                                        attendanceAllGroups.push(val * 100);
                                    }
                                });
                            });

                            let averageAttendance;
                            // console.log(attendanceAllGroups);
                            if (attendanceAllGroups.length > 0) {
                                if (attendanceAllGroups.length > 1) {
                                    averageAttendance =
                                        attendanceAllGroups.reduce((a, b) => a + b) / attendanceAllGroups.length;
                                } else {
                                    return _round(attendanceAllGroups[0]);
                                }
                            } else {
                                return '';
                            }
                            return _round(averageAttendance);
                        };

                        const getTeensAttendedPct = () => {
                            let numTeensAttendedThreePlusSessions = 0;
                            let totalTeens = 0;

                            data.groups.treatmentGroupList.forEach(tg => {
                                tg.childRoles.participantList.forEach(participant => {
                                    const didAttend =
                                        participant.assignmentProgressSummary.frequency &&
                                        participant.assignmentProgressSummary.frequency.find(f => f.value === '1');
                                    const didNotAttend =
                                        participant.assignmentProgressSummary.frequency &&
                                        participant.assignmentProgressSummary.frequency.find(f => f.value === '0');
                                    if (didAttend && didNotAttend) {
                                        if (didAttend.valueCount >= 5 || didNotAttend.valueCount >= 5) {
                                            if (didAttend.valueCount >= 5) {
                                                numTeensAttendedThreePlusSessions++;
                                            }
                                            totalTeens++;
                                        } else if (didAttend.valueCount + didNotAttend.valueCount >= 5) {
                                            if (didAttend.valueCount >= 5) {
                                                numTeensAttendedThreePlusSessions++;
                                            }
                                            totalTeens++;
                                        }
                                    } else if (didAttend) {
                                        if (didAttend.valueCount >= 5) {
                                            numTeensAttendedThreePlusSessions++;
                                        }
                                        totalTeens++;
                                    } else if (didNotAttend) {
                                        if (didNotAttend.valueCount >= 5) {
                                            totalTeens++;
                                        }
                                    } else {
                                        totalTeens++;
                                    }
                                });
                            });
                            return _round((numTeensAttendedThreePlusSessions / totalTeens) * 100);
                        };

                        convertedData = {
                            teenAttendance: getAttendanceRate(['youthAttAvg']),
                            targetAttendance: 80,
                            teensAttendedPct: getTeensAttendedPct(),
                            targetTeensPct: 80,
                            caregiverAttendance: getAttendanceRate(['caregiverAttAvg']),
                            targetCaregiverAttendance: 80,
                        };
                    } else {
                        convertedData = {
                            teenAttendance: 60,
                            targetAttendance: 80,
                            teensAttendedPct: 70,
                            targetTeensPct: 80,
                            caregiverAttendance: 85,
                            targetCaregiverAttendance: 80,
                        };
                    }
                    return convertedData;
                },
            },
            logAttendanceTracker: {
                totalSessions: 7,
            },
            logComments: {
                query: logCommentsQuery,
                convertDataStructure: data => {
                    let convertedData = [];
                    if (data.length > 0) {
                        convertedData = data.map(group => {
                            return {
                                id: group['group.groupId'],
                                groupName: group['group.label'],
                                sessionData: [
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 1,
                                        sessionDate: group['childRoles.metricspawn.session1.substr'],
                                        teenComment:
                                            group['childRoles.metricspawn.session1.devTags.YouthAttendanceLogComments'],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session1.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 2,
                                        sessionDate: group['childRoles.metricspawn.session2.substr'],
                                        teenComment:
                                            group['childRoles.metricspawn.session2.devTags.YouthAttendanceLogComments'],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session2.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 3,
                                        sessionDate: group['childRoles.metricspawn.session3.substr'],
                                        teenComment:
                                            group['childRoles.metricspawn.session3.devTags.YouthAttendanceLogComments'],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session3.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 4,
                                        sessionDate: group['childRoles.metricspawn.session4.substr'],
                                        teenComment:
                                            group['childRoles.metricspawn.session4.devTags.YouthAttendanceLogComments'],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session4.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 5,
                                        sessionDate: group['childRoles.metricspawn.session5.substr'],
                                        teenComment:
                                            group['childRoles.metricspawn.session5.devTags.YouthAttendanceLogComments'],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session5.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 6,
                                        sessionDate: group['childRoles.metricspawn.session6.substr'],
                                        teenComment:
                                            group['childRoles.metricspawn.session6.devTags.YouthAttendanceLogComments'],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session6.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                    {
                                        id: group['group.groupId'],
                                        groupName: group['group.label'],
                                        session: 7,
                                        sessionDate: group['childRoles.metricspawn.session7.substr'],
                                        teenComment:
                                            group['childRoles.metricspawn.session7.devTags.YouthAttendanceLogComments'],
                                        caregiverComment:
                                            group[
                                                'childRoles.metricspawn.session7.devTags.CaregiverAttendanceLogComments'
                                            ],
                                    },
                                ],
                            };
                        });
                    } else {
                        convertedData = [
                            {
                                id: 10,
                                groupName: 'My Group',
                                sessionData: [
                                    {
                                        id: 10,
                                        groupName: 'My Group',
                                        session: 1,
                                        sessionDate: '2020-10-01',
                                        teenComment: 'Teen Comment Session 1',
                                        caregiverComment: 'Caregiver comment session 1',
                                    },
                                    {
                                        id: 10,
                                        groupName: 'My Group',
                                        session: 2,
                                        sessionDate: '2020-10-08',
                                        teenComment: 'teen comment session 2',
                                        caregiverComment: 'caregiver comment session 2',
                                    },
                                ],
                            },
                        ];
                    }
                    return convertedData;
                },
            },
        },
    },
    {
        ...OutcomesGroupReportTemplate,
        widgetProps: {
            outcomesSummaryCallouts: {
                query: outcomesGCSummaryCalloutsQuery,
                convertDataStructure: data => {
                    let convertedData = {};

                    if (data && data.groups.treatmentGroupList.length > 0) {
                        const getTeenScores = () => {
                            let teenPreScores = [];
                            let teenPostScores = [];
                            data.groups.treatmentGroupList.forEach(group => {
                                const teenPre = group.childRoles.participantSummary.youthPre;
                                const teenPost = group.childRoles.participantSummary.youthPost;
                                if (teenPre !== null) {
                                    teenPreScores.push(Number(teenPre));
                                }
                                if (teenPost !== null) {
                                    teenPostScores.push(Number(teenPost));
                                }
                            });

                            const preScore = _round(_mean(teenPreScores), 1);
                            const postScore = _round(_mean(teenPostScores), 1);
                            return {
                                pre: Number.isNaN(preScore) ? '' : preScore,
                                post: Number.isNaN(postScore) ? '' : postScore,
                            };
                        };

                        const getCaregiverScores = () => {
                            let caregiverPreScores = [];
                            let caregiverPostScores = [];
                            data.groups.treatmentGroupList.forEach(group => {
                                const caregiverPre = group.childRoles.participantSummary.caregiverPre;
                                const caregiverPost = group.childRoles.participantSummary.caregiverPost;
                                if (caregiverPre !== null) {
                                    caregiverPreScores.push(Number(caregiverPre));
                                }
                                if (caregiverPost !== null) {
                                    caregiverPostScores.push(Number(caregiverPost));
                                }
                            });

                            const preScore = _round(_mean(caregiverPreScores), 1);
                            const postScore = _round(_mean(caregiverPostScores), 1);
                            return {
                                pre: Number.isNaN(preScore) ? '' : preScore,
                                post: Number.isNaN(postScore) ? '' : postScore,
                            };
                        };

                        convertedData = {
                            teenPre: getTeenScores().pre,
                            teenPost: getTeenScores().post,
                            caregiverPre: getCaregiverScores().pre,
                            caregiverPost: getCaregiverScores().post,
                        };
                    } else {
                        convertedData = {
                            teenPre: 65.25,
                            teenPost: 73.75,
                            caregiverPre: 70.0,
                            caregiverPost: 75.0,
                        };
                    }
                    // console.log({ convertedData });
                    return convertedData;
                },
            },
            outcomesSummaryChart: {
                query: outcomesGCSummaryChartQuery,
            },
            outcomesGroupComments: {
                query: outcomesGCCommentsQuery,
                convertDataStructure: data => {
                    let convertedData = [];
                    if (data.length > 0) {
                        convertedData = data.map(participant => {
                            const teenCommentObject = participant.teenList.find(
                                i => i.deployment.label === 'Youth Post'
                            );
                            const caregiverCommentObject = participant.caregiverList.find(
                                i => i.deployment.label === 'Caregiver Post'
                            );
                            return {
                                id: participant['pawn.pawnId'],
                                participantName: participant['fields.participantId'],
                                teenComment: teenCommentObject ? teenCommentObject.devTags.YouthPostComment : '',
                                caregiverComment: caregiverCommentObject
                                    ? caregiverCommentObject.devTags.CaregiverPostComment
                                    : '',
                            };
                        });
                    } else {
                        convertedData = [
                            {
                                id: 1,
                                participantName: 'Youth One',
                                teenComment: 'I learned a lot.',
                                caregiverComment: 'Talking to my youth about racism is important.',
                            },
                            {
                                id: 2,
                                participantName: 'Youth Two',
                                teenComment: 'Youth Two Youth Comment.',
                                caregiverComment: 'Youth Two Caregiver Comment',
                            },
                        ];
                    }
                    return convertedData;
                },
            },
            outcomesParticipantCharts: {
                query: outcomesParticipantChartsQuery,
                convertDataStructure: data => {
                    let convertedData = [];

                    if (data && data.roles.participantList.length > 0) {
                        convertedData = data.roles.participantList.map(participant => {
                            if (participant.teenList.length > 0 && participant.caregiverList.length > 0) {
                                const youthPreObject = participant.teenList.find(
                                    i => i.deployment.label === 'Youth Pre'
                                );
                                const youthPostObject = participant.teenList.find(
                                    i => i.deployment.label === 'Youth Post'
                                );
                                const caregiverPreObject = participant.caregiverList.find(
                                    i => i.deployment.label === 'Caregiver Pre'
                                );
                                const caregiverPostObject = participant.caregiverList.find(
                                    i => i.deployment.label === 'Caregiver Post'
                                );
                                return {
                                    name: participant.fields.participantId,
                                    data: [
                                        {
                                            id: 'Youth Summary',
                                            color: '#3673b6',
                                            data: [
                                                {
                                                    x: '',
                                                    y: null,
                                                },
                                                {
                                                    x: 'Pre',
                                                    y: youthPreObject
                                                        ? Number(youthPreObject.devTags.YouthPreScore)
                                                        : null,
                                                },
                                                {
                                                    x: 'Post',
                                                    y: youthPostObject
                                                        ? Number(youthPostObject.devTags.YouthPostScore)
                                                        : null,
                                                },
                                                {
                                                    x: ' ',
                                                    y: null,
                                                },
                                            ],
                                        },
                                        {
                                            id: 'Caregiver Summary',
                                            color: '#65a84b',
                                            data: [
                                                {
                                                    x: '',
                                                    y: null,
                                                },
                                                {
                                                    x: 'Pre',
                                                    y: caregiverPreObject
                                                        ? Number(caregiverPreObject.devTags.CaregiverPreScore)
                                                        : null,
                                                },
                                                {
                                                    x: 'Post',
                                                    y: caregiverPostObject
                                                        ? Number(caregiverPostObject.devTags.CaregiverPostScore)
                                                        : null,
                                                },
                                                {
                                                    x: ' ',
                                                    y: null,
                                                },
                                            ],
                                        },
                                    ],
                                };
                            }
                            return null;
                        });
                    } else {
                        convertedData = [
                            {
                                name: 'MB0101',
                                data: [
                                    {
                                        id: 'Youth Summary',
                                        color: '#3673b6',
                                        data: [
                                            {
                                                x: '',
                                                y: null,
                                            },
                                            {
                                                x: 'Pre',
                                                y: 60,
                                            },
                                            {
                                                x: 'Post',
                                                y: 70,
                                            },
                                            {
                                                x: ' ',
                                                y: null,
                                            },
                                        ],
                                    },
                                    {
                                        id: 'Caregiver Summary',
                                        color: '#65a84b',
                                        data: [
                                            {
                                                x: '',
                                                y: null,
                                            },
                                            {
                                                x: 'Pre',
                                                y: 65,
                                            },
                                            {
                                                x: 'Post',
                                                y: 75,
                                            },
                                            {
                                                x: ' ',
                                                y: null,
                                            },
                                        ],
                                    },
                                ],
                            },
                            {
                                name: 'MB0102',
                                data: [
                                    {
                                        id: 'Youth Summary',
                                        color: '#3673b6',
                                        data: [
                                            {
                                                x: '',
                                                y: null,
                                            },
                                            {
                                                x: 'Pre',
                                                y: 65,
                                            },
                                            {
                                                x: 'Post',
                                                y: 75,
                                            },
                                            {
                                                x: ' ',
                                                y: null,
                                            },
                                        ],
                                    },
                                    {
                                        id: 'Caregiver Summary',
                                        color: '#65a84b',
                                        data: [
                                            {
                                                x: '',
                                                y: null,
                                            },
                                            {
                                                x: 'Pre',
                                                y: 70,
                                            },
                                            {
                                                x: 'Post',
                                                y: 72,
                                            },
                                            {
                                                x: ' ',
                                                y: null,
                                            },
                                        ],
                                    },
                                ],
                            },
                        ];
                    }
                    const filteredData = convertedData.filter(d => d !== null);
                    return filteredData;
                },
            },
        },
    },
    {
        ...OutcomesGroupComparisonReportTemplate,
        widgetProps: {
            outcomesSummaryCallouts: {
                query: outcomesGCSummaryCalloutsQuery,
                convertDataStructure: data => {
                    let convertedData = {};

                    if (data && data.groups.treatmentGroupList.length > 0) {
                        const getTeenScores = () => {
                            let teenPreScores = [];
                            let teenPostScores = [];
                            data.groups.treatmentGroupList.forEach(group => {
                                const teenPre = group.childRoles.participantSummary.youthPre;
                                const teenPost = group.childRoles.participantSummary.youthPost;
                                if (teenPre !== null) {
                                    teenPreScores.push(Number(teenPre));
                                }
                                if (teenPost !== null) {
                                    teenPostScores.push(Number(teenPost));
                                }
                            });

                            const preScore = _round(_mean(teenPreScores), 1);
                            const postScore = _round(_mean(teenPostScores), 1);
                            return {
                                pre: Number.isNaN(preScore) ? '' : preScore,
                                post: Number.isNaN(postScore) ? '' : postScore,
                            };
                        };

                        const getCaregiverScores = () => {
                            let caregiverPreScores = [];
                            let caregiverPostScores = [];
                            data.groups.treatmentGroupList.forEach(group => {
                                const caregiverPre = group.childRoles.participantSummary.caregiverPre;
                                const caregiverPost = group.childRoles.participantSummary.caregiverPost;
                                if (caregiverPre !== null) {
                                    caregiverPreScores.push(Number(caregiverPre));
                                }
                                if (caregiverPost !== null) {
                                    caregiverPostScores.push(Number(caregiverPost));
                                }
                            });

                            const preScore = _round(_mean(caregiverPreScores), 1);
                            const postScore = _round(_mean(caregiverPostScores), 1);
                            return {
                                pre: Number.isNaN(preScore) ? '' : preScore,
                                post: Number.isNaN(postScore) ? '' : postScore,
                            };
                        };

                        convertedData = {
                            teenPre: getTeenScores().pre,
                            teenPost: getTeenScores().post,
                            caregiverPre: getCaregiverScores().pre,
                            caregiverPost: getCaregiverScores().post,
                        };
                    } else {
                        convertedData = {
                            teenPre: 65.25,
                            teenPost: 73.75,
                            caregiverPre: 70.0,
                            caregiverPost: 75.0,
                        };
                    }
                    // console.log({ convertedData });
                    return convertedData;
                },
            },
            outcomesSummaryChart: {
                query: outcomesGCSummaryChartQuery,
            },
            outcomesGroupComments: {
                query: outcomesGCCommentsQuery,
                convertDataStructure: data => {
                    let convertedData = [];
                    if (data.length > 0) {
                        convertedData = data.map(participant => {
                            const teenCommentObject = participant.teenList.find(
                                i => i.deployment.label === 'Youth Post'
                            );
                            const caregiverCommentObject = participant.caregiverList.find(
                                i => i.deployment.label === 'Caregiver Post'
                            );
                            return {
                                id: participant['pawn.pawnId'],
                                participantName: participant['fields.participantId'],
                                teenComment: teenCommentObject ? teenCommentObject.devTags.YouthPostComment : '',
                                caregiverComment: caregiverCommentObject
                                    ? caregiverCommentObject.devTags.CaregiverPostComment
                                    : '',
                            };
                        });
                    } else {
                        convertedData = [
                            {
                                id: 1,
                                participantName: 'Youth One',
                                teenComment: 'I learned a lot.',
                                caregiverComment: 'Talking to my teen about racism is important.',
                            },
                            {
                                id: 2,
                                participantName: 'Youth Two',
                                teenComment: 'Youth Two Youth Comment.',
                                caregiverComment: 'Youth Two Caregiver Comment',
                            },
                        ];
                    }
                    return convertedData;
                },
            },
        },
    },
    {
        ...OutcomesIndividualReportTemplate,
        widgetProps: {
            outcomesIndividualSummaryCallouts: {
                query: outcomesIndividualSummaryCalloutsQuery,
            },
            outcomesIndividualSummaryChart: {
                query: outcomesIndividualSummaryChartQuery,
            },
            outcomesIndividualSummaryTable: {
                query: outcomesIndividualSummaryTableQuery,
            },
            outcomesIndividualComments: {
                query: outcomesIndividualCommentsQuery,
                convertDataStructure: data => {
                    // console.log(data);
                    let convertedData = [];
                    if (data.length > 0) {
                        convertedData = data.map(participant => {
                            const teenCommentObject = participant.teenList.find(
                                i => i.deployment.label === 'Youth Post'
                            );
                            const caregiverCommentObject = participant.caregiverList.find(
                                i => i.deployment.label === 'Caregiver Post'
                            );
                            return {
                                id: participant['pawn.pawnId'],
                                participantName: participant['fields.participantId'],
                                teenComment: teenCommentObject ? teenCommentObject.devTags.YouthPostComment : '',
                                caregiverComment: caregiverCommentObject
                                    ? caregiverCommentObject.devTags.CaregiverPostComment
                                    : '',
                            };
                        });
                    } else {
                        convertedData = [
                            {
                                id: 1,
                                participantName: 'Youth One',
                                teenComment: 'I learned a lot.',
                                caregiverComment: 'Talking to my youth about racism is important.',
                            },
                            {
                                id: 2,
                                participantName: 'Youth Two',
                                teenComment: 'Youth Two Youth Comment.',
                                caregiverComment: 'Youth Two Caregiver Comment',
                            },
                        ];
                    }
                    return convertedData;
                },
            },
        },
    },
];
