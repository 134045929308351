import React from 'react';
import { Field, CccisdInput, CccisdToggle } from 'cccisd-formik';
import { widgetBuilder } from 'cccisd-laravel-appdefs';

export const initialValues = {
    show: true,
    teenAttendance: {
        show: true,
        title: 'Youth Attendance',
        description: '',
    },
    teensAttended: {
        show: true,
        title: 'Youth Attended 3+ Sessions',
        description: '',
    },
    caregiverAttendance: {
        show: true,
        title: 'Caregiver Attendance',
        description: '',
    },
};

const Component = props => {
    return (
        <div>
            <Field name="show" component={CccisdToggle} label="Show Summary Callouts?" />
            <h4>Callout 1</h4>
            <Field name="teenAttendance.show" component={CccisdToggle} label="Show Callout 1?" />
            <Field name="teenAttendance.title" component={CccisdInput} label="Title" />
            <Field name="teenAttendance.description" component={CccisdInput} label="Description" />
            <h4>Callout 2</h4>
            <Field name="teensAttended.show" component={CccisdToggle} label="Show Callout 2?" />
            <Field name="teensAttended.title" component={CccisdInput} label="Title" />
            <Field name="teensAttended.description" component={CccisdInput} label="Description" />
            <h4>Callout 3</h4>
            <Field
                name="caregiverAttendance.show"
                component={CccisdToggle}
                label="Show Callout 3?"
            />
            <Field name="caregiverAttendance.title" component={CccisdInput} label="Title" />
            <Field
                name="caregiverAttendance.description"
                component={CccisdInput}
                label="Description"
            />
        </div>
    );
};

export default widgetBuilder({ initialValues })(Component);
