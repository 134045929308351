import React from 'react';
import PropTypes from 'prop-types';
import { Field, CccisdInput, CccisdWysiwyg } from 'cccisd-formik';
import { BuilderBlock, reportTemplateBuilder } from 'cccisd-laravel-appdefs';
import filterFields from '../filterFields.js';
import ColorPicker from '../../ColorPicker';
import widgets from '../widgets.js';

export const initialValues = {
    filterFields: filterFields.map(item => item.name),
    otherFilterOptions: [],
    colors: { primary: '#3673b6' },
    header: {
        title: 'Session Fidelity Report',
        subtitle: 'Tracks the degree to which the program is delivered as intended',
        groupLeaderText: 'Group Leader Name:',
        reportingPeriodText: 'Reporting Period:',
        createdDateText: 'Report Created On:',
        description:
            'This report shows to what degree group sessions are adhering to the program model',
        numGroupsText: 'Number of groups included:',
        numParticipantsText: 'Number of individuals included:',
    },
    body: {
        heading: '',
    },
    widgets: widgets.reduce((obj, w) => {
        obj[w.handle] = w.builderInitialValues;
        return obj;
    }, {}),
};

const Component = props => {
    return (
        <div>
            <BuilderBlock title="Header">
                <Field name="header.title" component={CccisdInput} label="Title" />
                <Field name="header.subtitle" component={CccisdInput} label="Subtitle" />
                <Field name="header.description" component={CccisdInput} label="Description" />
                <Field
                    name="header.reportingPeriodText"
                    component={CccisdInput}
                    label="Reporting Period Text"
                />
                <Field
                    name="header.createdDateText"
                    component={CccisdInput}
                    label="Created Date Text"
                />
                <BuilderBlock title="Subheader">
                    <Field
                        name="header.groupLeaderText"
                        component={CccisdInput}
                        label="Group Leader Text"
                    />
                    <Field
                        name="header.numGroupsText"
                        component={CccisdInput}
                        label="Number of Groups Text"
                    />
                    <Field
                        name="header.numParticipantsText"
                        component={CccisdInput}
                        label="Number of Individuals Text"
                    />
                </BuilderBlock>
            </BuilderBlock>
            <BuilderBlock title="Body">
                <Field name="body.heading" component={CccisdWysiwyg} label="Page Heading" />
            </BuilderBlock>
            <BuilderBlock title="Color">
                <Field name="colors.primary">
                    {({ field, form }) => {
                        return <ColorPicker field={field} form={form} />;
                    }}
                </Field>
            </BuilderBlock>
        </div>
    );
};

Component.propTypes = {
    onValidChange: PropTypes.func,
    settings: PropTypes.object,
};

export default reportTemplateBuilder({ filterFields, widgets })(Component);
