import Builder, { initialValues as builderInitialValues } from './Builder';
import Player from './Player';

export default {
    handle: 'outcomesSummaryCallouts',
    label: 'Summary Callouts',
    builderInitialValues,
    builder: Builder,
    player: Player,
};
