import React from 'react';
import PropTypes from 'prop-types';
import { Html } from 'cccisd-wysiwyg';
import { Report, Page } from 'cccisd-laravel-report';
import { reportTemplatePlayer } from 'cccisd-laravel-appdefs';
import widgets from '../widgets.js';
import filterFields from '../filterFields.js';
import ReportHeader from '../../ReportHeader';
import style from './style.css';

const Component = props => {
    const {
        fidelitySGSummaryCallouts,
        fidelitySGOverallChart,
        overallFidelityInfoTab,
        fidelitySGAverageChart,
        fidelitySGAdherenceChart,
        componentsCallouts,
        componentsInfoTab,
        fidelitySummaryTable,
        summaryTableInfoTab,
        fidelitySGFacilitatorChart,
        fidelitySGFacilitatorTable,
        fidelityAdherenceTable,
        fidelityComments,
        recommendations,
    } = props.widgets;

    const {
        body: { heading },
    } = props.settings;

    return (
        <Report>
            <Page>
                <div className={style.wrapper}>
                    <ReportHeader
                        settings={props.settings}
                        filters={props.filters}
                        isPreview={props.isPreview}
                        data={props.data}
                    />
                    <div className={style.reportBodyText}>
                        <Html content={heading} />
                    </div>
                    {fidelitySGSummaryCallouts}
                    {fidelitySGOverallChart}
                    {fidelitySGAverageChart}
                    {fidelitySummaryTable}
                    {overallFidelityInfoTab}
                    {fidelitySGFacilitatorChart}
                    {fidelitySGFacilitatorTable}
                    {summaryTableInfoTab}
                    {componentsCallouts}
                    {fidelitySGAdherenceChart}
                    {fidelityAdherenceTable}
                    {componentsInfoTab}
                    {fidelityComments}
                    {recommendations}
                </div>
            </Page>
        </Report>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
    widgets: PropTypes.object,
    data: PropTypes.object,
};

export default reportTemplatePlayer({
    reportHandle: 'outcomes',
    widgets,
    getFilterFields: props =>
        filterFields.map(field =>
            field.name === 'groupIds'
                ? {
                      ...field,
                      initialValue: props.data.groups.treatmentGroupList
                          .map(tg => tg.group.groupId)
                          .slice(0, 1),
                  }
                : field
        ),
})(Component);
