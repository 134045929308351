import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery';
import componentsQuery from './componentsQuery.graphql';
import ComponentsChart from './components/ComponentsChartBar';
import _round from 'lodash/round';
import style from './style.css';

const FidelityAverageChart = props => {
    const [hidden, setHidden] = useState(true);
    const { title, description, show, chart } = props.settings;
    const { isPreview, query } = props;

    const [data, loading] = useQuery({
        query: isPreview ? componentsQuery : query,
        variables: props.filters,
    });

    // See overallFidelityChart for comments
    useEffect(() => {
        setTimeout(() => {
            setHidden(false);
        }, 300);
    }, []);

    if (loading) {
        return <Loader loading />;
    }

    const chartData = props.convertDataStructure(data);

    return (
        <>
            {show && (
                <div className={style.wrapper}>
                    <div>
                        <span style={{ fontWeight: 'bold', fontSize: '1.5em' }}>{title}</span>
                    </div>
                    <div>{description}</div>
                    {hidden ? <div /> : <ComponentsChart data={chartData} settings={chart} />}
                </div>
            )}
        </>
    );
};

FidelityAverageChart.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

FidelityAverageChart.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        // console.log(data);
        let convertedData = [];

        if (data && data.groups.treatmentGroupList.length > 0) {
            const groupData =
                data.groups.treatmentGroupList[0].childRoles.metricspawn.assignmentProgressSummary;

            convertedData = [
                {
                    id: 'Overall Fidelity',
                    'Overall Fidelity': _round(groupData.facilitatorFidelityScoreAvg, 1),
                },
                {
                    id: 'Foundational Fidelity',
                    'Foundational Fidelity': _round(
                        groupData.FidelityFoundationlElementsScoreAvg,
                        1
                    ),
                },
                {
                    id: 'Leadership Fidelity',
                    'Leadership Fidelity': _round(groupData.FidelityLeadershipScoreAvg, 1),
                },
            ];
        } else {
            convertedData = [
                {
                    id: 'Overall Fidelity',
                    'Overall Fidelity': 4.3,
                },
                {
                    id: 'Foundational Fidelity',
                    'Foundational Fidelity': 3.4,
                },
                {
                    id: 'Leadership Fidelity',
                    'Leadership Fidelity': 2.7,
                },
            ];
        }
        // console.log({ convertedData });
        return convertedData;
    },
};

export default FidelityAverageChart;
