import React from 'react';
import PropTypes from 'prop-types';
import Loader from 'cccisd-loader';
import useQuery from '../../../../useQuery.js';
import calloutsQuery from './calloutsQuery.graphql';
import Callout from './Callout';
import _round from 'lodash/round';
import _mean from 'lodash/mean';
import style from './style.css';

const Component = props => {
    const { show } = props.settings;
    const [data, loading] = useQuery({
        query: props.query ? props.query : calloutsQuery,
        variables: props.filters,
    });

    if (loading) {
        return <Loader loading />;
    }

    const calloutsData = props.convertDataStructure(data);

    const { teenPre, teenPost, caregiverPre, caregiverPost } = calloutsData;

    return (
        <>
            {show && (
                <div className={style.callouts}>
                    {props.settings.teenPre.show && (
                        <Callout
                            handle="teenPre"
                            settings={{
                                ...props.settings.teenPre,
                                colors: props.settings.colors,
                            }}
                            data={teenPre}
                        />
                    )}
                    {props.settings.teenPost.show && (
                        <Callout
                            handle="teenPost"
                            settings={{
                                ...props.settings.teenPost,
                                colors: props.settings.colors,
                            }}
                            data={teenPost}
                        />
                    )}
                    {props.settings.caregiverPre.show && (
                        <Callout
                            handle="caregiverPre"
                            settings={{
                                ...props.settings.caregiverPre,
                                colors: props.settings.colors,
                            }}
                            data={caregiverPre}
                        />
                    )}
                    {props.settings.caregiverPost.show && (
                        <Callout
                            handle="caregiverPost"
                            settings={{
                                ...props.settings.caregiverPost,
                                colors: props.settings.colors,
                            }}
                            data={caregiverPost}
                        />
                    )}
                </div>
            )}
        </>
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    isPreview: PropTypes.bool,
    filters: PropTypes.object,
};

Component.defaultProps = {
    filters: {},
    convertDataStructure: data => {
        // console.log(data);
        let convertedData = {};

        if (data && data.roles.participantList.length > 0) {
            const getTeenScores = () => {
                let teenPreScores = [];
                let teenPostScores = [];
                data.roles.participantList.forEach(participant => {
                    participant.teenList.forEach(teen => {
                        const teenPre = teen.devTags.YouthPreScore;
                        const teenPost = teen.devTags.YouthPostScore;
                        if (teenPre !== null) {
                            teenPreScores.push(Number(teenPre));
                        }
                        if (teenPost !== null) {
                            teenPostScores.push(Number(teenPost));
                        }
                    });
                });

                const preScore = _round(_mean(teenPreScores), 1);
                const postScore = _round(_mean(teenPostScores), 1);
                return {
                    pre: Number.isNaN(preScore) ? '' : preScore,
                    post: Number.isNaN(postScore) ? '' : postScore,
                };
            };

            const getCaregiverScores = () => {
                let caregiverPreScores = [];
                let caregiverPostScores = [];
                data.roles.participantList.forEach(participant => {
                    participant.caregiverList.forEach(caregiver => {
                        const caregiverPre = caregiver.devTags.CaregiverPreScore;
                        const caregiverPost = caregiver.devTags.CaregiverPostScore;
                        if (caregiverPre !== null) {
                            caregiverPreScores.push(Number(caregiverPre));
                        }
                        if (caregiverPost !== null) {
                            caregiverPostScores.push(Number(caregiverPost));
                        }
                    });
                });
                const preScore = _round(_mean(caregiverPreScores), 1);
                const postScore = _round(_mean(caregiverPostScores), 1);
                return {
                    pre: Number.isNaN(preScore) ? '' : preScore,
                    post: Number.isNaN(postScore) ? '' : postScore,
                };
            };

            convertedData = {
                teenPre: getTeenScores().pre,
                teenPost: getTeenScores().post,
                caregiverPre: getCaregiverScores().pre,
                caregiverPost: getCaregiverScores().post,
            };
        } else {
            convertedData = {
                teenPre: 65.25,
                teenPost: 73.75,
                caregiverPre: 70.0,
                caregiverPost: 75.0,
            };
        }
        // console.log({ convertedData });
        return convertedData;
    },
};

export default Component;
