import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Color from 'color';
import moment from 'moment';
import Table from 'cccisd-graphql-table';
import { BrowserOnly } from 'cccisd-laravel-report';
// import mockClient from '../_mockApolloClient';
import IconComment from 'cccisd-icons/bubble-dots3';
import IconPlus from 'cccisd-icons/plus2';
import IconMinus from 'cccisd-icons/minus2';
import IconMenu from 'cccisd-icons/menu7';
import IconSpinner from 'cccisd-icons/spinner8';
import style from './style.css';

const Comment = props => {
    const { groupName, sessionDate, teenComment, caregiverComment } = props;

    if (teenComment || caregiverComment) {
        return (
            <div className={style.commentWrapper}>
                <div className={style.info}>
                    <span style={{ fontWeight: 'bold' }}>{groupName}</span>{' '}
                    {moment(sessionDate).format('MMM Do YYYY')}
                </div>
                <div className={style.commentBlocks}>
                    {teenComment && (
                        <div className={style.commentBlock}>
                            <div className={style.commentHeading}>Youth Comment</div>
                            <div className={style.comment}>{teenComment}</div>
                        </div>
                    )}
                    {caregiverComment && (
                        <div className={style.commentBlock}>
                            <div className={style.commentHeading}>Caregiver Comment</div>
                            <div className={style.comment}>{caregiverComment}</div>
                        </div>
                    )}
                </div>
            </div>
        );
    }
};

const Session = props => {
    const { sessionData, colors } = props;

    const _renderComments = () =>
        sessionData.map(c => (
            <Comment
                key={c.teenComment + c.sessionDate}
                groupName={c.groupName}
                sessionDate={c.sessionDate}
                teenComment={c.teenComment}
                caregiverComment={c.caregiverComment}
                colors={colors}
            />
        ));

    return (
        <div className={style.sessionWrapper}>
            <div className={style.head} style={colors ? { backgroundColor: colors.primary } : {}}>
                Session {sessionData[0].session}
            </div>
            <div className={style.comments}>{_renderComments()}</div>
        </div>
    );
};

const Component = props => {
    const [boxOpen, setBoxOpen] = useState(true);

    const { title } = props.settings;
    const { colors } = props;

    const _renderCommentBox = data => {
        let sessions = {
            1: [],
            2: [],
            3: [],
            4: [],
            5: [],
            6: [],
            7: [],
        };
        data.forEach(group => {
            group.sessionData.forEach(sd => {
                if (sd.teenComment || sd.caregiverComment) {
                    sessions[sd.session].push(sd);
                }
            });
        });
        return Object.keys(sessions)
            .map(key => {
                if (sessions[key].length > 0) {
                    return (
                        <Session
                            key={'session' + key}
                            sessionData={sessions[key]}
                            colors={colors}
                        />
                    );
                }
                return null;
            })
            .filter(d => d !== null);
    };

    const renderComments = tableProps => {
        if (!tableProps.loading) {
            return (
                <div>
                    <BrowserOnly>
                        <div className="btn-group" style={{ marginBottom: '0.7em' }}>
                            <button
                                type="button"
                                className={`btn btn-sm btn-default dropdown-toggle ${
                                    style.multipurposeButton
                                } ${tableProps.loading ? style.rotating : ''}`}
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {tableProps.loading ? <IconSpinner /> : <IconMenu />}
                            </button>
                            <ul className="dropdown-menu">
                                <li>
                                    <a href="" onClick={tableProps.loadData}>
                                        Reload data
                                    </a>
                                </li>
                                <li>
                                    <a href="" onClick={tableProps.downloadCsv}>
                                        Download CSV
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </BrowserOnly>
                    <div
                        className={style.wrapper}
                        style={colors ? { borderColor: colors.primary } : {}}
                    >
                        <div
                            className={style.header}
                            style={colors ? { backgroundColor: colors.primary } : {}}
                        >
                            <div>
                                <IconComment spaceRight />
                                {title}
                            </div>
                            <button
                                type="button"
                                className={`btn btn-sm ${style.menuButton}`}
                                onClick={() => setBoxOpen(!boxOpen)}
                                style={
                                    colors
                                        ? { backgroundColor: Color(colors.primary).darken(0.2) }
                                        : {}
                                }
                            >
                                {boxOpen ? <IconMinus /> : <IconPlus />}
                            </button>
                        </div>
                        {boxOpen
                            ? _renderCommentBox(props.convertDataStructure(tableProps.data))
                            : null}
                    </div>
                </div>
            );
        }
        return null;
    };

    return (
        <Table
            // apolloClient={props.isPreview ? mockClient : undefined}
            graphqlVariables={{
                groupIds: props.filters.groupIds,
                startDate: props.filters.startDate,
                endDate: props.filters.endDate,
            }}
            query={props.query}
            columns={[
                { name: 'group.label', label: 'Group Label' },
                {
                    name: 'childRoles.metricspawn.session1.devTags.YouthAttendanceLogComments',
                    label: 'Session 1 Youth Attendance Log Comments',
                },
                {
                    name: 'childRoles.metricspawn.session1.devTags.CaregiverAttendanceLogComments',
                    label: 'Session 1 Caregiver Attendance Log Comments',
                },
                {
                    name: 'childRoles.metricspawn.session2.devTags.YouthAttendanceLogComments',
                    label: 'Session 2 Youth Attendance Log Comments',
                },
                {
                    name: 'childRoles.metricspawn.session2.devTags.CaregiverAttendanceLogComments',
                    label: 'Session 2 Caregiver Attendance Log Comments',
                },
                {
                    name: 'childRoles.metricspawn.session3.devTags.YouthAttendanceLogComments',
                    label: 'Session 3 Youth Attendance Log Comments',
                },
                {
                    name: 'childRoles.metricspawn.session3.devTags.CaregiverAttendanceLogComments',
                    label: 'Session 3 Caregiver Attendance Log Comments',
                },
                {
                    name: 'childRoles.metricspawn.session4.devTags.YouthAttendanceLogComments',
                    label: 'Session 4 Youth Attendance Log Comments',
                },
                {
                    name: 'childRoles.metricspawn.session4.devTags.CaregiverAttendanceLogComments',
                    label: 'Session 4 Caregiver Attendance Log Comments',
                },
                {
                    name: 'childRoles.metricspawn.session5.devTags.YouthAttendanceLogComments',
                    label: 'Session 5 Youth Attendance Log Comments',
                },
                {
                    name: 'childRoles.metricspawn.session5.devTags.CaregiverAttendanceLogComments',
                    label: 'Session 5 Caregiver Attendance Log Comments',
                },
            ]}
            render={renderComments}
        />
    );
};

Component.propTypes = {
    settings: PropTypes.object,
    data: PropTypes.array,
    isPreview: PropTypes.bool,
    query: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    colors: PropTypes.object,
    convertDataStructure: PropTypes.func,
    filters: PropTypes.object,
};

Component.defaultProps = {
    convertDataStructure: data => {
        let convertedData = [];
        if (data.length > 0) {
            convertedData = data.map(group => {
                return {
                    id: group['group.groupId'],
                    groupName: group['group.label'],
                    sessionData: [
                        {
                            id: group['group.groupId'],
                            groupName: group['group.label'],
                            session: 1,
                            sessionDate: group['childRoles.metricspawn.session1.substr'],
                            teenComment:
                                group[
                                    'childRoles.metricspawn.session1.devTags.YouthAttendanceLogComments'
                                ],
                            caregiverComment:
                                group[
                                    'childRoles.metricspawn.session1.devTags.CaregiverAttendanceLogComments'
                                ],
                        },
                        {
                            id: group['group.groupId'],
                            groupName: group['group.label'],
                            session: 2,
                            sessionDate: group['childRoles.metricspawn.session2.substr'],
                            teenComment:
                                group[
                                    'childRoles.metricspawn.session2.devTags.YouthAttendanceLogComments'
                                ],
                            caregiverComment:
                                group[
                                    'childRoles.metricspawn.session2.devTags.CaregiverAttendanceLogComments'
                                ],
                        },
                        {
                            id: group['group.groupId'],
                            groupName: group['group.label'],
                            session: 3,
                            sessionDate: group['childRoles.metricspawn.session3.substr'],
                            teenComment:
                                group[
                                    'childRoles.metricspawn.session3.devTags.YouthAttendanceLogComments'
                                ],
                            caregiverComment:
                                group[
                                    'childRoles.metricspawn.session3.devTags.CaregiverAttendanceLogComments'
                                ],
                        },
                        {
                            id: group['group.groupId'],
                            groupName: group['group.label'],
                            session: 4,
                            sessionDate: group['childRoles.metricspawn.session4.substr'],
                            teenComment:
                                group[
                                    'childRoles.metricspawn.session4.devTags.YouthAttendanceLogComments'
                                ],
                            caregiverComment:
                                group[
                                    'childRoles.metricspawn.session4.devTags.CaregiverAttendanceLogComments'
                                ],
                        },
                        {
                            id: group['group.groupId'],
                            groupName: group['group.label'],
                            session: 5,
                            sessionDate: group['childRoles.metricspawn.session5.substr'],
                            teenComment:
                                group[
                                    'childRoles.metricspawn.session5.devTags.YouthAttendanceLogComments'
                                ],
                            caregiverComment:
                                group[
                                    'childRoles.metricspawn.session5.devTags.CaregiverAttendanceLogComments'
                                ],
                        },
                    ],
                };
            });
        } else {
            convertedData = [
                {
                    id: 10,
                    groupName: 'My Group',
                    sessionData: [
                        {
                            id: 10,
                            groupName: 'My Group',
                            session: 1,
                            sessionDate: '2020-10-01',
                            teenComment: 'Youth Comment Session 1',
                            caregiverComment: 'Caregiver comment session 1',
                        },
                        {
                            id: 10,
                            groupName: 'My Group',
                            session: 2,
                            sessionDate: '2020-10-08',
                            teenComment: 'Youth comment session 2',
                            caregiverComment: 'caregiver comment session 2',
                        },
                    ],
                },
            ];
        }
        return convertedData;
    },
};

export default Component;
